import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { REACT_APP_ENV } from "../store/api/config/config";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfigProduction = {
  apiKey: "AIzaSyAEHaO8ajJ_V2O7LH1A7JNjNiMOeXiUS78",
  authDomain: "divethru-14f14.firebaseapp.com",
  databaseURL: "https://divethru-14f14.firebaseio.com",
  projectId: "divethru-14f14",
  storageBucket: "divethru-14f14.appspot.com",
  messagingSenderId: "5071479695",
  appId: "1:5071479695:web:707074775ac30edf62e1fa",
  measurementId: "G-Z5K8DRDQVR",
};

const firebaseConfigStage = {
  apiKey: "AIzaSyDcHj9a11GOu-lWZjvHsKvvOPX1I32vErA",
  authDomain: "divethru-test.firebaseapp.com",
  databaseURL: "https://divethru-test.firebaseio.com",
  projectId: "divethru-test",
  storageBucket: "divethru-test.appspot.com",
  messagingSenderId: "274931545677",
  appId: "1:274931545677:web:36f7275f5de5fe94ce0e04",
  measurementId: "G-62H1DT93WR",
};

let firebaseConfig;

if (REACT_APP_ENV === "production") {
  firebaseConfig = firebaseConfigProduction;
} else {
  firebaseConfig = firebaseConfigStage;
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
