import React from "react";
import { PulseLoader } from "react-spinners";

const Loader = ({ left }) => {
  return (
    <div className="overlay">
      <PulseLoader
        color="#36d7b7"
        style={{ position: "fixed", top: "50%", left: left ?? "48%" }}
      />
    </div>
  );
};

export default Loader;
