export const LocationSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
    >
      <path
        d="M8 0C5.879 0.00238211 3.84555 0.846002 2.34578 2.34578C0.846002 3.84555 0.00238211 5.879 0 8C0 11.255 2.363 13.958 4.866 16.819C5.658 17.725 6.478 18.662 7.208 19.61C7.30142 19.7312 7.4214 19.8293 7.55871 19.8968C7.69602 19.9644 7.84699 19.9995 8 19.9995C8.15301 19.9995 8.30398 19.9644 8.44129 19.8968C8.5786 19.8293 8.69858 19.7312 8.792 19.61C9.522 18.662 10.342 17.725 11.134 16.819C13.637 13.958 16 11.255 16 8C15.9976 5.879 15.154 3.84555 13.6542 2.34578C12.1544 0.846002 10.121 0.00238211 8 0ZM8 11C7.40666 11 6.82664 10.8241 6.33329 10.4944C5.83994 10.1648 5.45542 9.69623 5.22836 9.14805C5.0013 8.59987 4.94189 7.99667 5.05764 7.41473C5.1734 6.83279 5.45912 6.29824 5.87868 5.87868C6.29824 5.45912 6.83279 5.1734 7.41473 5.05764C7.99667 4.94189 8.59987 5.0013 9.14805 5.22836C9.69623 5.45542 10.1648 5.83994 10.4944 6.33329C10.8241 6.82664 11 7.40666 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55871 10.6839 8.79565 11 8 11Z"
        fill="#C2CCE1"
      />
    </svg>
  );
};
