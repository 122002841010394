import http from "./config/NetworkClient";

export default class AuthApi {
  static async login(payload) {
    const response = await http.post("/api/v3/divethru/fauth", payload);
    return response;
  }
  static async userInfo() {
    const res = await http.get("/api/v3/divethru/user/info");
    return res;
  }
  static async updateUserInfo(payload, token) {
    const res = await http.put("/api/v3/divethru/user/info", payload, token);
    return res;
  }
  static async userProfileInfo() {
    const res = await http.get("/api/v3/user-profile");
    return res;
  }
  static async updateUserProfileInfo(payload) {
    const res = await http.put("/api/v3/user-profile", payload);
    return res;
  }

  static async validatedMobileNumber(payload) {
    const res = await http.get(`/api/validate/phone-number`, payload);
    return res;
  }
  static async getFamilyMembers() {
    const res = await http.get("/api/v3/user-relatives");
    return res;
  }
  static async addFamilyMembers(payload) {
    const res = await http.post("/api/v3/user-relative", payload);
    return res;
  }
  static async deleteFamilyMembers(id) {
    const res = await http.del(`/api/v3/user-relative/${id}`);
    return res;
  }
  static async updateNotification(param) {
    const res = await http.put(`/api/v3/user-appointment-notification`, param);
    return res;
  }
  static async inactiveUser() {
    const response = await http.put("/api/v3/user/delete");
    return response;
  }
}
