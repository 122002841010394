import { constant } from "../constant";

const initialState = {
  loading: true,
  result: {},
  errMsg: "",
  
};

const TherapyReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_THERAPY_INDEX:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_THERAPY_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
   
        errMsg: "",
      };
    
    case constant.GET_THERAPY_INDEX_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_THERAPY_INDEX:
      return {
        ...state,
        loading: false,
        result: {},
    
        errMsg: "",
      };
    default:
      return state;
  }
};

export default TherapyReducer;
