import { Modal } from "antd";
import React from "react";
import styles from "./styles.module.scss";
import { CloseButton } from "../../assets/svg";

const ConfirmDelete = ({
  showModal,
  setShowModal,
  submitClick,
  title,
  des,
  des1,
  closeBtnText,
  noBtnText,
  showCloseIcon,
  width,
}) => {
  return (
    <Modal
      open={showModal}
      centered={true}
      closable={false}
      width={width ?? 486}
      footer={<></>}
    >
      {showCloseIcon && (
        <div className={styles.crossBtn} onClick={() => setShowModal(false)}>
          <CloseButton height={"30"} width={"30"} />
        </div>
      )}
      <p className={styles.title}>{title}</p>
      {des && <p className={styles.des}>{des}</p>}
      {des1 && <p className={styles.des}>{des1}</p>}

      {(noBtnText || closeBtnText) && (
        <div className={styles.flexEnd}>
          {noBtnText && (
            <div className={styles.noBtn} onClick={() => setShowModal(false)}>
              {noBtnText}
            </div>
          )}
          {closeBtnText && (
            <div className={styles.yesBtn} onClick={() => submitClick()}>
              {closeBtnText}
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ConfirmDelete;
