import "./custom.css";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import PhoneInput from "react-phone-input-2";
import dayjs from "dayjs";
import { ActionCreators } from "../../store";
import { bindActionCreators } from "redux";
import googleTagFire from "../../utills/googleTagFire";
import { showMessege } from "../../components/CustomMessage";
import { AuthApi, SettingsApi } from "../../store/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import AccountSetupPaymentModal from "../../components/Modals/AccountSetupPaymentModal";
import Loader from "../../components/Loader";

export default function AccountSetup() {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const userData = useSelector((state) => state.AuthReducer); //store data
  const [visibleAddNewCardModal, setvisibleAddNewCardModal] = useState(false);
  const [cardList, setcardList] = useState();
  const { setUserInfoData, setInfo } = bindActionCreators(
    ActionCreators,
    dispatch
  );
  const loginStatus = useSelector((state) => state.AuthReducer.loginStatus); //store data
  const navigate = useNavigate();


  const [phoneNumberErrorMsg, setphoneNumberErrorMsg] = useState(null)
  const [emergencyErrMsg, setemergencyErrMsg] = useState(null)
  const phone_number = Form.useWatch('phone_number', form);
  const emergency_contact_number = Form.useWatch('emergency_contact_number', form);

  useEffect(() => {
    if (phone_number !== null) {
      const delayDebounceFn = setTimeout(() => {
        phone_number && validateMobileNumber(phone_number, 'phoneNo')
      }, 800);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone_number]);

  useEffect(() => {
    if (emergency_contact_number != null) {
      const delayDebounceFn = setTimeout(() => {
        emergency_contact_number && validateMobileNumber(emergency_contact_number, 'emergencyNo')
      }, 800);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emergency_contact_number]);

  useEffect(() => {
    if (!loginStatus) {
      setLoading(false);
      navigate({ pathname: "/sign-up", search: "?next_url=account-setup" });
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  const validateMobileNumber = async (mobile_no, type) => {
    let mobile_number = mobile_no?.length > 3 && mobile_no?.charAt(0) === "+"
      ? mobile_no
      : mobile_no?.length > 3 &&
        mobile_no?.charAt(0) !== "+"
        ? `+${mobile_no}`
        : null;

    const response = await AuthApi.validatedMobileNumber({
      phone_number: mobile_number
    });
    if (response?.status === 200) {
      type === 'phoneNo' ? response?.data?.is_valid ? setphoneNumberErrorMsg(null) : setphoneNumberErrorMsg(response?.data?.message ?? 'Invalid number') : response?.data?.is_valid ? setemergencyErrMsg(null) : setemergencyErrMsg(response?.data?.message ?? 'Invalid number')
    }

  }

  const fetchData = async () => {
    setLoading(true);
    const apiCall = await AuthApi.userProfileInfo();
    if (apiCall?.status === 200) {
      setUserInfoData(apiCall.data);
      googleTagFire("Account Setup", "Page View", apiCall?.data);
    } else {
      showMessege("error", "Profile data is not uploaded!");
    }
    setLoading(false);
  };

  const submitData = async (val) => {
    if (dayjs(val?.date_of_birth).isAfter(dayjs().add(-13, "years"))) {
      showMessege("error", "You must be 13 years or older to proceed");
      return;
    }

    const { first_name, last_name } = val;
    let payload = {
      user: {
        first_name: first_name.trim(),
        last_name: last_name.trim(),
      },
      date_of_birth: val?.date_of_birth
        ? dayjs(val?.date_of_birth, "YYYY-MM-DD").format("YYYY-MM-DD")
        : null,
      gender: val?.gender,
      pronoun: val?.pronoun,
      emergency_contact_name: val?.emergency_contact_name,
      // emergency_contact_number: val?.emergency_contact_number,
      emergency_contact_number:
        val?.emergency_contact_number?.length > 3 &&
          val?.emergency_contact_number?.charAt(0) === "+"
          ? val?.emergency_contact_number
          : val?.emergency_contact_number?.length > 3 &&
            val?.emergency_contact_number?.charAt(0) !== "+"
            ? `+${val?.emergency_contact_number}`
            : null,

      emergency_contact_relation: val.emergency_contact_relation,
      phone_number:
        val?.phone_number?.length > 3 && val?.phone_number?.charAt(0) === "+"
          ? val?.phone_number
          : val?.phone_number?.length > 3 &&
            val?.phone_number?.charAt(0) !== "+"
            ? `+${val?.phone_number}`
            : null,
    };

    googleTagFire("Account Setup", null, payload);
    setLoading(true);
    const submitProfileData = await AuthApi.updateUserProfileInfo(payload);
    if (
      submitProfileData?.status === 200 ||
      submitProfileData?.status === 201
    ) {
      const userData = await AuthApi.userInfo();
      if (userData.status === 200) {
        setInfo(userData.data);
      }
      await fetchData();
      showMessege(
        "success",
        submitProfileData?.data?.message ?? "User profile has been updated!"
      );
      getCardList();
    } else {
      showMessege(
        "error",
        submitProfileData !== 422
          ? submitProfileData?.data?.detail ??
          "Error occured! Please try again or later!"
          : "Error! User profile info has not been updated"
      );
    }
    setLoading(false);
  };

  const getCardList = async () => {
    setLoading(true);
    const response = await SettingsApi.getCardList();
    const res = await SettingsApi.getBillingsList();
    if (response?.status === 200 && res?.status === 200) {
      // setBillingDetails(res?.data);
      setcardList(response?.data?.results);
      setLoading(false);
      setvisibleAddNewCardModal(true);
      googleTagFire("Account Setup", "Page View", response?.data?.results);
    } else {
      showMessege("error", response?.data?.detail ?? res?.data?.detail);
      setvisibleAddNewCardModal(true);

      setLoading(false);
    }
  };

  const onChangeDefault = async (param) => {
    if (!param?.default) {
      if (cardList?.length > 1) {
        showMessege("error", "Please select another card as default first");
      } else {
        showMessege(
          "error",
          "Please add another card and set that card as default first"
        );
      }
    } else {
      setLoading(true);
      const response = await SettingsApi.changeDefaultCard(param);
      if (response?.status === 200) {
        await getCardList();
      }
    }
  };

  const deletCardHandle = async (id) => {
    const res = await SettingsApi.deleteCard(id);
    if (res?.status === 200 || res?.status === 201) {
      await getCardList();
      showMessege(
        "success",
        res?.data?.message ?? "Card has been deleted successfully."
      );
    } else if (res?.status === 400) {
      if (res?.data?.detail === "Square error while disable card") {
        showMessege("error", "This card deletion is not possible!");
      } else {
        showMessege(
          "error",
          res?.data?.detail ?? "This card deletion is not possible!"
        );
      }
    } else {
      showMessege("error", "This card deletion is not possible!");
    }
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <div style={{ textAlign: "left" }}>
        <Row style={{ justifyContent: "center" }}>
          <Col lg={15} md={20} sm={23} xs={22}>
            <Space direction="vertical">
              <Typography.Title level={2}>Account Setup</Typography.Title>
              <Typography.Title level={4}>
                Hey, we need just a few of your details!
              </Typography.Title>
              <Typography.Text level={4}>
                We need to set up an account to book appointments for you.
                Please fill up these information and help us to match you with
                the best therapist.
              </Typography.Text>
              <Divider orientation="left">General Information</Divider>
              <Form
                layout="vertical"
                form={form}
                onFinish={submitData}
                initialValues={{
                  first_name: userData?.userProfileInfo?.user?.first_name ?? "",
                  last_name: userData?.userProfileInfo?.user?.last_name ?? "",
                  email: userData?.userProfileInfo?.user?.email ?? "",
                  // phone_number: userData?.userProfileInfo?.phone_number ?? null,
                  phone_number: userData?.userProfileInfo?.phone_number?.length > 3 &&
                    userData?.userProfileInfo?.phone_number?.charAt(0) === "+"
                    ? userData?.userProfileInfo?.phone_number
                    : userData?.userProfileInfo?.phone_number?.length > 3 &&
                      userData?.userProfileInfo?.phone_number?.charAt(0) !== "+"
                      ? `+${userData?.userProfileInfo?.phone_number}`
                      : null,
                  date_of_birth: userData?.userProfileInfo?.date_of_birth
                    ? dayjs(
                      userData?.userProfileInfo?.date_of_birth,
                      "YYYY-MM-DD"
                    )
                    : null,
                  gender: userData?.userProfileInfo?.gender ?? null,
                  pronoun: userData?.userProfileInfo?.pronoun ?? null,
                  emergency_contact_name:
                    userData?.userProfileInfo?.emergency_contact_name ?? null,
                  emergency_contact_number:
                    // userData?.userProfileInfo?.emergency_contact_number ?? null,
                    userData?.userProfileInfo?.emergency_contact_number?.length > 3 &&
                      userData?.userProfileInfo?.emergency_contact_number?.charAt(0) === "+"
                      ? userData?.userProfileInfo?.emergency_contact_number
                      : userData?.userProfileInfo?.emergency_contact_number?.length > 3 &&
                        userData?.userProfileInfo?.emergency_contact_number?.charAt(0) !== "+"
                        ? `+${userData?.userProfileInfo?.emergency_contact_number}`
                        : null,
                  emergency_contact_relation:
                    userData?.userProfileInfo?.emergency_contact_relation ??
                    null,
                }}
              >
                <Row gutter={16}>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      label={"First Name"}
                      name={"first_name"}
                      rules={[
                        {
                          required: true,
                          message: `First name is required!`,
                        },
                      ]}
                    >
                      <Input className={styles.input} />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      label={"Last Name"}
                      name={"last_name"}
                      rules={[
                        {
                          required: true,
                          message: `Last name is required!`,
                        },
                      ]}
                    >
                      <Input className={styles.input} />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      label={"Phone Number"}
                      name={"phone_number"}
                      validateStatus="error"
                      help={phoneNumberErrorMsg}
                      rules={[
                        {
                          required: true,
                          message: `Phone number is required!`,
                        },
                      ]}
                    >
                      {/* <Input className={styles.input} /> */}
                      <PhoneInput
                        country={"ca"}
                        specialLabel=""
                        containerStyle={
                          {
                            // marginTop: 10,
                            // marginBottom: 10,
                          }
                        }
                        inputStyle={{
                          width: "100%",
                          height: 45,
                          borderRadius: 10,
                          border: "1px solid #d9d9d9",
                          backgroundColor: "#fff",
                        }}
                        buttonStyle={{
                          borderTopLeftRadius: 10,
                          borderBottomLeftRadius: 10,
                        }}
                        dropdownStyle={{ textAlign: "left" }}
                      //   disabled={loading}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      label={"Date of Birth"}
                      name={"date_of_birth"}
                      rules={[
                        {
                          required: true,
                          message: `Date of birth is required!`,
                        },
                      ]}
                    >
                      <DatePicker
                        //   disabled={loading}
                        format="YYYY-MM-DD"
                        //   defaultValue={
                        //     userData?.userProfileInfo?.date_of_birth
                        //       ? dayjs(
                        //           userData?.userProfileInfo?.date_of_birth,
                        //           "YYYY-MM-DD"
                        //         )
                        //       : null
                        //   }
                        disabledDate={(date) => {
                          if (date > dayjs()) {
                            return true;
                          }
                          return false;
                        }}
                        style={{ width: "100%", height: 45, borderRadius: 10 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      label={"Gender"}
                      name={"gender"}
                      rules={[
                        {
                          required: true,
                          message: `Gender is required!`,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select gender"
                        //   size="small"
                        className={styles.dropDown}
                      //   disabled={loading}
                      >
                        <Select.Option value="MAN">Man</Select.Option>
                        <Select.Option value="WOMAN">Woman</Select.Option>
                        <Select.Option value="NON_BINARY">
                          Non Binary
                        </Select.Option>
                        <Select.Option value="OTHER">Other</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      label={"Pronouns"}
                      name={"pronoun"}
                      colon={false}
                    >
                      <Select
                        placeholder="Select Pronouns"
                        className={styles.dropDown}
                      >
                        <Select.Option value="She/Her">She/Her</Select.Option>
                        <Select.Option value="He/Him">He/Him</Select.Option>
                        <Select.Option value="They/Them">
                          They/Them
                        </Select.Option>
                        <Select.Option value="She/They">She/They</Select.Option>
                        <Select.Option value="He/They">He/They</Select.Option>
                        <Select.Option value="Xe/Xem">Xe/Xem</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Divider orientation="left">
                  Emergency Contact Information
                </Divider>

                <Row gutter={16}>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      label={"Emergency Contact Name"}
                      name={"emergency_contact_name"}
                      rules={[
                        {
                          required: true,
                          message: `Emergency contact name is required!`,
                        },
                      ]}
                    >
                      <Input className={styles.input} />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      label={"Emergency Contact Number"}
                      name={"emergency_contact_number"}
                      validateStatus="error"
                      help={emergencyErrMsg}
                      rules={[
                        {
                          required: true,
                          message: `Emergency contact number is required!`,
                        },
                      ]}
                    >
                      {/* <Input className={styles.input} /> */}
                      <PhoneInput
                        country={"ca"}
                        // specialLabel=""
                        containerStyle={{
                          // marginTop: 10,
                          marginBottom: 10,
                        }}
                        inputStyle={{
                          width: "100%",
                          height: 45,
                          borderRadius: 10,
                          backgroundColor: "#fff",
                        }}
                        buttonStyle={{
                          borderTopLeftRadius: 10,
                          borderBottomLeftRadius: 10,
                        }}
                        dropdownStyle={{ textAlign: "left" }}
                      // disabled={loading}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      label={"Relationship"}
                      name={"emergency_contact_relation"}
                      colon={false}
                    >
                      <Input className={styles.input} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                shape="round"
                onClick={() => {
                  form.submit();

                }}
                disabled={phoneNumberErrorMsg || emergencyErrMsg}
                style={{ fontWeight: "bold", marginBottom: 20 }}
                loading={loading}
              >
                Proceed To Payment{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  size="1x"
                  color="white"
                  style={{ marginLeft: 10 }}
                />
              </Button>
            </Space>

            <Modal
              open={visibleAddNewCardModal}
              className={styles.modal}
              bodyStyle={{
                padding: 0,
                paddingLeft: 25,
                // paddingTop: 25,
                paddingRight: 25,
              }}
              centered={true}
              closable={true}
              width={500}
              onCancel={() => {
                setvisibleAddNewCardModal(false);
              }}
              title={
                <Typography.Title level={3} style={{ textAlign: "center" }}>
                  Payment Details
                </Typography.Title>
              }
              footer={<></>}
              destroyOnClose
            >
              <AccountSetupPaymentModal
                cardList={cardList}
                onChangeDefault={onChangeDefault}
                deletCardHandle={deletCardHandle}
                setvisibleAddNewCardModal={setvisibleAddNewCardModal}
              />
            </Modal>
          </Col>
        </Row>
      </div>
    );
  }
}
