import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import logo from "../../assets/divethruLogo.png";
import { Button, Form, Input } from "antd";
import { showMessege } from "../../components/CustomMessage";
import { AuthApi } from "../../store/api";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth } from "../../utills/firebase-config";
import facebook from "./../../assets/facebookIcon.png";
import google from "./../../assets/googleIcon.png";
import { facebookProvider, googleProvider } from "../../utills/AuthMethods";
import Loader from "../../components/Loader";

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const AccountDelete = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [socialLoding, setSocialLoding] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const onClickSave = async (val) => {
    console.log("kjsadkjaksjdas984729137497293175975");
    if (validateEmail(val.email) === null) {
      showMessege("error", "Please enter valid email");
      return;
    }
    setLoading(true);
    signInWithEmailAndPassword(auth, val.email, val.password)
      .then(async (res) => {
        if (res?.user?.accessToken) {
          const response = await AuthApi.login({
            fire_token: res?.user?.accessToken,
          });
          localStorage.setItem("token", response?.data?.jwt);
          if (response?.data?.jwt) {
            setTimeout(async () => {
              const res = await AuthApi.inactiveUser();
              if (res?.status === 200) {
                showMessege(
                  "error",
                  res?.data?.message ??
                    "User Delete Request Submitted Successfully"
                );
                setLoading(false);
              } else {
                showMessege(
                  "error",
                  "Error! Please contact with us to delete this account."
                );
                setLoading(false);
              }
            }, 500);
          } else {
            showMessege("error", "User is inactive");
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("error is: ", e);
        showMessege("error", "Invalid Credentials!");
        setLoading(false);
      });
  };

  const socialSignIn = async (provider) => {
    setSocialLoding(true);
    await provider?.addScope("email");
    signInWithPopup(auth, provider)
      .then(async (res) => {
        if (res?.user?.accessToken) {
          const response = await AuthApi.login({
            fire_token: res?.user?.accessToken,
          });
          localStorage.setItem("token", response?.data?.jwt);
          if (response?.data?.jwt) {
            setTimeout(async () => {
              const res = await AuthApi.inactiveUser();
              if (res?.status === 200) {
                showMessege(
                  "error",
                  res?.data?.message ??
                    "User Delete Request Submitted Successfully"
                );
                setSocialLoding(false);
              } else {
                showMessege(
                  "error",
                  "Error! Please contact with us to delete this account."
                );
                setSocialLoding(false);
              }
            }, 500);
          } else {
            showMessege("error", "User is inactive");
            setSocialLoding(false);
          }
        } else {
          setSocialLoding(false);
        }
      })
      .catch((error) => {
        setSocialLoding(false);
        const errorMessage = error?.message;
        if (
          errorMessage ===
            "Firebase: Error (auth/account-exists-with-different-credential)." ||
          errorMessage.includes("account-exists-with-different-credential")
        ) {
          showMessege(
            "error",
            "User signed up with google. Please try to sign in with google."
          );
        }
        if (errorMessage === "Firebase: Error (auth/popup-closed-by-user).") {
          showMessege("error", "Auth pop up is cancelled by user.");
        } else {
          showMessege("error", "Wrong credentials! Please try again.");
        }
        // console.error("Error from sign in: ", errorMessage);
      });
  };

  return (
    <div className={styles.container}>
      {socialLoding && <Loader />}
      <img src={logo} className={styles.logo} alt="n/a" />
      <p className={styles.header}>Delete Account</p>
      <div className={styles.innerContainer}>
        <Form
          colon={false}
          name="basic"
          layout="vertical"
          form={form}
          initialValues={{}}
          onFinish={onClickSave}
          onFinishFailed={() => {}}
          autoComplete="off"
          scrollToFirstError={true}
        >
          <Form.Item
            name={"email"}
            label={<label className={styles.label}>Email</label>}
            rules={[
              {
                required: true,
                message: "Please enter your email!",
              },
            ]}
          >
            <Input
              type="email"
              className={styles.nameInputStyle}
              placeholder="Enter your email"
            />
          </Form.Item>
          <Form.Item
            name={"password"}
            label={<label className={styles.label}>Password</label>}
            rules={[
              {
                required: true,
                message: "Please enter your password!",
              },
            ]}
          >
            <Input
              type="password"
              className={styles.nameInputStyle}
              placeholder="Enter your email"
            />
          </Form.Item>

          <Form.Item
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              size="large"
              loading={loading}
              className={styles.submit}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      <p>or</p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="google_img"
          src={google}
          height={34}
          width={33}
          style={{ marginRight: 20, cursor: "pointer" }}
          onClick={() => socialSignIn(googleProvider)}
        />
        <img
          alt="fb_img"
          src={facebook}
          height={40}
          width={40}
          style={{ cursor: "pointer" }}
          onClick={() => socialSignIn(facebookProvider)}
        />
      </div>
    </div>
  );
};

export default AccountDelete;
