import { Button, Popover, Space } from "antd";
import styles from "./styles.module.scss";
import React from "react";
import { QuestionMark } from "../../assets/svg/QuestionMark";

const content = (
  <Space direction="vertical" size={"middle"} style={{ width: 500 }}>
    <p className={styles.d1} style={{ paddingBottom: 0, marginBottom: 0 }}>
      Scoring Guide
    </p>
    <p className={styles.l2}>
      Your responses are added up to provide a total clinical score. The score
      ranges are listed below, with higher scores meaning higher levels of
      distress.  <br />
      <br />
      0-4: Healthy
      <br />
      <br /> 5-9: Healthy and low level of distress <br />
      <br />
      10-14: Mild level of distress  <br />
      <br />
      15-19: Moderate level of distress <br />
      <br />
      20-25: Moderate to severe amount of distress <br />
      <br />
      25+: Severe amount of distress
    </p>
    <div className={styles.l1}>Citations</div>
    <div className={styles.o3}>
      CORE-10: Barkham, M., Bewick, B., Mullin, T., Gilbody, S., Connell, J.,
      Cahill, J., Mellor-Clark, J., Richards, D., Unsworth, G., & Evans, C.
      (2013). The CORE‐10: A short measure of psychological distress for routine
      use in the psychological therapies. Counselling and Psychotherapy
      Research, 13(1), 3-13.
      <br />
      <br /> Connell, J. & Barkham, M. (2007). CORE-10 User Manual, Version 1.1.
      CORE System Trust & CORE Information Management Systems Ltd.
    </div>
  </Space>
);

export default function ScoringGuide() {
  return (
    <Popover placement="bottom" content={content}>
      <Button
        type="text"
        size="small"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <QuestionMark />
      </Button>
    </Popover>
  );
}
