import {
  Card,
  Col,
  DatePicker,
  Image,
  Input,
  Progress,
  Row,
  Space,
  Typography,
  Spin,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import leftArrow from "../../assets/leftArrow.png";
import { showMessege } from "../../components/CustomMessage";
import MultiSelect from "../../components/ManualQuestionnaire/MultiSelect";
import SingleSelect from "../../components/ManualQuestionnaire/SingleSelect";
import { AuthApi, SettingsApi } from "../../store/api";
import { SQUARE_APP_ID } from "../../store/api/config/config";
import NetworkClient from "../../store/api/config/NetworkClient";
import styles from "./styles.module.scss";
import Loader from "../../components/Loader";
import googleTagFire from "../../utills/googleTagFire";
import PhoneInput from "react-phone-input-2";

const { TextArea } = Input;

export default function ManualQuestionnaire() {
  const navigation = useNavigate();
  const loginStatus = useSelector((state) => state.AuthReducer.loginStatus); //store data
  const dob = new Date();
  const year = dob.getFullYear();
  const month = dob.getMonth();
  const day = dob.getDate();
  const [loading, setloading] = useState(false);
  const [apiData, setapiData] = useState([]);
  const [navigationArray, setnavigationArray] = useState([]);
  const [visibleIndex, setvisibleIndex] = useState(0);
  const [progress, setprogress] = useState(0);
  const [selectedOptionList, setselectedOptionList] = useState({});
  const [inputText, setinputText] = useState(null);
  const [date, setDate] = useState();
  const [singleSelectedOption, setsingleSelectedOption] = useState([]);
  const [multiSelectedOptions, setmultiSelectedOptions] = useState([]);
  const [isDateSelected, setisDateSelected] = useState(false);
  const [isSubmitted, setisSubmitted] = useState(false);
  const [manualSubmissionId, setmanualSubmissionId] = useState();
  const id = window.localStorage.getItem("locationId");
  const [card, setcard] = useState();
  const [submitButton, setSubmitButtonLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (selectedOptionList?.manual_form !== undefined) {
      setprogress(parseFloat(apiData?.[visibleIndex]?.percentage));
    }

    if (apiData?.[visibleIndex]?.question_type === "PAYMENT") {
      initializeCard();
    } else {
      setcard(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleIndex, selectedOptionList, apiData]);

  useEffect(() => {
    let currentQuestionObject = apiData?.[visibleIndex];
    let index = selectedOptionList?.answers?.findIndex(
      (item) => item.manual_question === currentQuestionObject?.id
    );
    if (index > -1) {
      if (
        currentQuestionObject?.question_type === "TEXT" ||
        currentQuestionObject?.question_type === "NUMBER"
      ) {
        setinputText(selectedOptionList?.answers?.[index]?.text);
      } else if (currentQuestionObject?.question_type === "DOB") {
        setDate(new Date(selectedOptionList?.answers?.[index]?.text));
      } else if (currentQuestionObject?.question_type === "SINGLE_SELECT") {
        let obj = currentQuestionObject?.options?.find(
          (d) => d?.name === selectedOptionList?.answers?.[index]?.options?.[0]
        );

        setsingleSelectedOption([obj]);
      } else if (currentQuestionObject?.question_type === "MULTI_SELECT") {
        let array = [];

        for (let a of selectedOptionList?.answers?.[index]?.options) {
          for (let b of currentQuestionObject?.options) {
            if (b?.name === a) {
              array.push(b);
            }
          }
        }

        setmultiSelectedOptions(array);
      } else {
        setinputText("");
        setsingleSelectedOption([]);
        setmultiSelectedOptions([]);
        setDate(new Date(year - 13, month, day));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleIndex, apiData, selectedOptionList]);

  const initializeCard = async () => {
    const payments = await window?.Square?.payments(SQUARE_APP_ID);

    if (card === undefined) {
      const card = await payments?.card();
      await card?.attach("#card");
      setcard(card);
    }
  };

  const createNewCard = async (token) => {
    let payload = {
      default: true,
      card_token: token,
    };
    const response = await SettingsApi.addNewCard(payload);
    if (response?.status === 201) {
      return response?.data?.id;
    } else {
      showMessege("error", response?.data?.detail);
    }
  };

  const getData = async () => {
    setloading(true);
    const response = await NetworkClient.getWithoutAuth(
      "api/v3/manual-matching-question?platform_type=ALL"
    );
    if (response.status === 200) {
      if (response?.data) {
        setapiData(response?.data?.manual_questions);
      } else {
      }
      setloading(false);
    }
  };

  const canProceed = () => {
    if (apiData?.[visibleIndex]?.is_required) {
      if (apiData?.[visibleIndex]?.question_type === "TEXT") {
        if (
          inputText?.trim().length === 0 ||
          inputText?.trim().length === undefined
        ) {
          return false;
        }
      } else if (apiData?.[visibleIndex]?.question_type === "NUMBER") {
        if (inputText === null || inputText === undefined) {
          return false;
        }
      } else if (apiData?.[visibleIndex]?.question_type === "SINGLE_SELECT") {
        if (singleSelectedOption?.length === 0) {
          return false;
        }
      } else if (apiData?.[visibleIndex]?.question_type === "MULTI_SELECT") {
        if (multiSelectedOptions?.length === 0) {
          return false;
        }
      } else if (apiData?.[visibleIndex]?.question_type === "DOB") {
        if (date === undefined || date == null) {
          return false;
        }
      }
    }
    return true;
  };

  const onBackClick = () => {
    if (visibleIndex === 0) {
      navigation("/select-studio", { replace: true });
    } else {
      let index = apiData?.findIndex(
        (a) => a?.id === navigationArray[navigationArray.length - 1]
      );
      setvisibleIndex(index);

      const newArray = navigationArray.slice(0, -1); // Create a new array without the last element

      setnavigationArray(newArray);
    }
  };

  const onClickNext = async () => {
    if (!canProceed()) {
      showMessege("error", "Please answer the question.");
      return;
    }
    setSubmitButtonLoading(true);
    let currentQuestionObject = apiData?.[visibleIndex];
    let isVisibleIndexSelected = false;

    let mainObj = {
      manual_form: currentQuestionObject?.manual_form,
      studio_location: id,
      platform_type: "WEB",
      answers: [],
    };

    let index = selectedOptionList?.answers?.findIndex(
      (item) => item?.manual_question === currentQuestionObject?.id
    );
    let alreadyAnswered = false;
    let answersArray = selectedOptionList?.answers
      ? selectedOptionList?.answers
      : [];

    if (index > -1) {
      //already answered
      alreadyAnswered = true;
    } else {
      //not answered yet
      alreadyAnswered = false;
    }

    if (inputText) {
      if (alreadyAnswered) {
        answersArray[index].text = inputText;
      } else {
        let answerObject = {
          manual_question: currentQuestionObject?.id,
          text: inputText,
          options: [],
        };
        answersArray.push(answerObject);
      }

      if (currentQuestionObject?.next_question) {
        let index = apiData?.findIndex(
          (data) => data?.id === currentQuestionObject?.next_question
        );
        setvisibleIndex(index);
        isVisibleIndexSelected = true;
      }
    } else if (singleSelectedOption?.length > 0) {
      let array = singleSelectedOption?.map((d) => d?.name);
      if (alreadyAnswered) {
        answersArray[index].options = array;
      } else {
        let answerObject = {
          manual_question: currentQuestionObject?.id,
          text: null,
          options: array,
        };
        answersArray.push(answerObject);
      }

      let optionHasNext = singleSelectedOption?.find((d) => d?.next_question);

      if (optionHasNext) {
        let index = apiData?.findIndex(
          (data) => data?.id === optionHasNext?.next_question
        );
        setvisibleIndex(index);
        isVisibleIndexSelected = true;
      }

      //   console.log('Usama optionHasNext', optionHasNext);
    } else if (multiSelectedOptions?.length > 0) {
      let array = multiSelectedOptions?.map((d) => d?.name);
      if (alreadyAnswered) {
        answersArray[index].options = array;
      } else {
        let answerObject = {
          manual_question: currentQuestionObject?.id,
          text: null,
          options: array,
        };
        answersArray.push(answerObject);
      }
      if (currentQuestionObject?.next_question) {
        let index = apiData?.findIndex(
          (data) => data?.id === currentQuestionObject?.next_question
        );
        setvisibleIndex(index);
        isVisibleIndexSelected = true;
      }
    } else if (isDateSelected) {
      if (alreadyAnswered) {
        answersArray[index].text = dayjs(date).format("YYYY-MM-DD");
      } else {
        let answerObject = {
          manual_question: currentQuestionObject?.id,
          text: dayjs(date).format("YYYY-MM-DD"),
          options: [],
        };
        answersArray.push(answerObject);
      }
      if (currentQuestionObject?.next_question) {
        let index = apiData?.findIndex(
          (data) => data?.id === currentQuestionObject?.next_question
        );
        setvisibleIndex(index);
        isVisibleIndexSelected = true;
      }
    } else if (apiData?.[visibleIndex]?.question_type === "PAYMENT") {
      const result = await card?.tokenize();
      if (result?.status === "Invalid") {
        result?.errors?.map((er) => showMessege("error", er?.message));
        return null;
      } else {
        let answerObject = {
          manual_question: currentQuestionObject?.id,
          text: await createNewCard(result?.token),
          options: [],
        };
        answersArray.push(answerObject);
      }
    }

    mainObj["answers"] = answersArray;

    setselectedOptionList(mainObj);

    if (visibleIndex < apiData?.length - 1 && !isVisibleIndexSelected) {
      setvisibleIndex((index) => index + 1);
    }
    setinputText(null);
    setsingleSelectedOption([]);
    setmultiSelectedOptions([]);
    setisDateSelected(false);

    setnavigationArray((array) => [...array, apiData?.[visibleIndex]?.id]);

    if (visibleIndex + 1 === apiData?.length) {
      submitData();
    } else {
      setSubmitButtonLoading(false);
    }
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
    );
  };

  const validateMobileNumber = async (mobile_no) => {
    let mobile_number =
      mobile_no?.length > 3 && mobile_no?.charAt(0) === "+"
        ? mobile_no
        : mobile_no?.length > 3 && mobile_no?.charAt(0) !== "+"
          ? `+${mobile_no}`
          : null;

    if (mobile_number === null) {
      showMessege("error", "Please enter valid number");
      return;
    }

    const response = await AuthApi.validatedMobileNumber({
      phone_number: mobile_number,
    });
    if (response?.status === 200) {
      if (response?.data?.is_valid) {
        onClickNext();
      } else {
        showMessege("error", response?.data?.message ?? "Invalid number");
      }
    }
  };

  const submitData = async () => {
    setloading(true);
    googleTagFire("Submit", null, selectedOptionList);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "LOCAL-TIMEZONE": moment.tz.guess(),
    };
    const response = await NetworkClient.post(
      "api/v3/manual-matching-question",
      selectedOptionList,
      headers
    );
    if (response?.status === 201 && response?.data?.id) {
      showMessege(
        "success",
        response?.data?.message ?? "Successfully submitted"
      );
      setmanualSubmissionId(response?.data?.id);
      setSubmitButtonLoading(false);
      setisSubmitted(true);
      setloading(false);
    } else {
      showMessege("error", response?.data?.detail ?? "Error to post form data");
      setloading(false);
      setSubmitButtonLoading(false);
    }
  };

  if (isSubmitted) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          marginTop: 30,
          flexDirection: "column",
        }}
      >
        <p className={styles.questionnaireName}>All Done!</p>
        <p
          className={styles.descriptionOne}
          style={{
            color:
              apiData?.[visibleIndex]?.question_type === "EMPTY" && "#000000",
          }}
        >
          Our team will review your responses and find the best fitting
          therapist match for you. We will reach out to you via phone or email
          with more instructions on the next steps in the process.
        </p>
        <Space size={"large"}>
          {!loginStatus && (
            <Card
              className={styles.nextBtn}
              bodyStyle={{ paddingTop: 10, paddingBottom: 10 }}
              onClick={() =>
                navigation("/sign-up", {
                  state: {
                    manual_submission_id: manualSubmissionId,
                  },
                })
              }
            >
              <Typography.Text className={styles.nextBtnText}>
                {"Create an Account"}
              </Typography.Text>
            </Card>
          )}
          <Card
            className={styles.returnHomeBtn}
            bodyStyle={{ paddingTop: 10, paddingBottom: 10 }}
            onClick={() => navigation("/matching-tools")}
          >
            <Typography.Text className={styles.nextBtnText}>
              {"Return Home"}
            </Typography.Text>
          </Card>
        </Space>
      </div>
    );
  }

  return (
    <div>
      {loading && <Loader />}
      <Image
        src={leftArrow}
        preview={false}
        alt="left-arrow"
        width={30}
        // style={{ position: "absolute", left: 250, top: 30 }}
        className={
          apiData?.[visibleIndex]?.question_type !== "EMPTY"
            ? styles.backBtn
            : styles.backBtnWithoutProgressbar
        }
        onClick={onBackClick}
      />
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          marginTop: 30,
          flexDirection: "column",
        }}
      >
        {apiData?.[visibleIndex]?.question_type !== "EMPTY" ? (
          <Progress
            percent={progress}
            showInfo={false}
            size={[, 20]}
            // strokeWidth={25}
            strokeColor={"#1BCDB7"}
            className={styles.progressBar}
          />
        ) : (
          <div style={{ marginBottom: 50 }}></div>
        )}
        <Space
          style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}
        >
          <p className={styles.questionnaireName}>
            {apiData?.[visibleIndex]?.name}{" "}
            {apiData?.[visibleIndex]?.is_required && (
              <span style={{ color: "red" }}> *</span>
            )}
          </p>
          <p
            className={styles.descriptionOne}
            style={{
              color:
                apiData?.[visibleIndex]?.question_type === "EMPTY" && "#000000",
            }}
          >
            {apiData?.[visibleIndex]?.description}
          </p>
          <p className={styles.descriptionTwo}>
            {apiData?.[visibleIndex]?.description1}
          </p>
        </Space>

        {apiData?.[visibleIndex]?.question_type === "EMAIL" && (
          <Input
            placeholder="Type your email here..."
            value={inputText}
            className={styles.input}
            onChange={(e) => setinputText(e.target.value)}
          />
        )}
        {apiData?.[visibleIndex]?.question_type === "SINGLE_SELECT" && (
          <SingleSelect
            data={apiData?.[visibleIndex]}
            setsingleSelectedOption={setsingleSelectedOption}
            singleSelectedOption={singleSelectedOption}
          />
        )}
        {apiData?.[visibleIndex]?.question_type === "MULTI_SELECT" && (
          <MultiSelect
            data={apiData?.[visibleIndex]}
            setmultiSelectedOptions={setmultiSelectedOptions}
            multiSelectedOptions={multiSelectedOptions}
          />
        )}
        {apiData?.[visibleIndex]?.question_type === "PAYMENT" && (
          <Col>
            {card === undefined && <Spin />}
            <div id="card"></div>
          </Col>
        )}
        {apiData?.[visibleIndex]?.question_type === "TEXT" && (
          <Row style={{ width: "85%", justifyContent: "center" }}>
            <Col span={18}>
              {apiData?.[visibleIndex]?.design_type === "BOX" ? (
                <Input
                  className={styles.input}
                  value={inputText}
                  onChange={(e) => {
                    if (apiData?.[visibleIndex]?.question_type === "TEXT") {
                      setinputText(e.target.value);
                    } else {
                      const regex = /^([+0-9\.\-\/])+$/; // eslint-disable-line
                      if (regex.test(e.target.value)) {
                        setinputText(e.target.value);
                      }
                    }
                  }}
                />
              ) : (
                <TextArea
                  placeholder="Type your answer here..."
                  className={styles.textArea}
                  style={{ resize: "none" }}
                  value={inputText}
                  autoFocus
                  onChange={(e) => setinputText(e.target.value)}
                />
              )}
            </Col>
          </Row>
        )}

        {apiData?.[visibleIndex]?.question_type === "NUMBER" && (
          <Row style={{ width: "85%", justifyContent: "center" }}>
            <Col>
              <PhoneInput
                country={"ca"}
                placeholder="Enter phone number"
                onChange={(e) => setinputText(e)}
                inputStyle={{
                  width: window?.innerWidth < 500 ? '69vw' : "40vw",
                  height: "5vh",
                  borderRadius: 10,
                  backgroundColor: "#f5f5f5",
                }}
                buttonStyle={{
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                }}
                dropdownStyle={{ textAlign: "left" }}
              />
            </Col>
          </Row>
        )}

        {apiData?.[visibleIndex]?.question_type === "DOB" && (
          <Row style={{ width: "85%", justifyContent: "center" }}>
            <Col span={18}>
              <DatePicker
                value={date ? dayjs(date) : null}
                onChange={(date) => {
                  setisDateSelected(true);
                  setDate(date);
                }}
                format={["MM/DD/YYYY", "MM/DD/YYYY"]}
                className={styles.DatePicker}
                placeholder="Select your birthdate"
                disabledDate={(date) => {
                  if (dayjs(date) < dayjs().add(-1, "day")) {
                    return false;
                  }
                  return true;
                }}
              />
            </Col>
          </Row>
        )}
        <div
          className={styles.nextBtn}
          bodyStyle={{ paddingTop: 10, paddingBottom: 10 }}
          onClick={() => {
            if (
              apiData?.[visibleIndex]?.question_type === "PAYMENT" &&
              card === undefined
            ) {
            } else if (apiData?.[visibleIndex]?.question_type === "EMAIL") {
              if (inputText) {
                if (validateEmail(inputText)) {
                  onClickNext();
                } else {
                  showMessege("error", "Please enter valid email");
                }
              }
            } else if (apiData?.[visibleIndex]?.question_type === "NUMBER") {
              if (inputText?.trim().length === 0) {
                showMessege("error", "Please enter valid number");
                return;
              }
              validateMobileNumber(inputText);
            } else {
              !submitButton && onClickNext();
            }
          }}
        >
          <p className={styles.nextBtnText}>
            {visibleIndex + 1 === apiData?.length && !loading
              ? "Submit"
              : visibleIndex + 1 === apiData?.length && loading
                ? "loading..."
                : "Next"}
          </p>
        </div>
      </div>
    </div>
  );
}
