import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { message } from "antd";

export const showMessege = (messageType = "success", messageText = null) => {
  if (typeof messageText === "string") {
    message.open({
      content: (
        <p style={{ paddingTop: 3, paddingBottom: 3, margin: 0 }}>
          {messageType === "success" ? (
            <CheckOutlined />
          ) : (
            <InfoCircleOutlined />
          )}
          {" " + messageText}
        </p>
      ),
      duration: 6,

      // className: "toast",
      // className: "alertCLass",
    });
  }
};
