import React from "react";
import { Space, Tag } from "antd";
import styles from "./styles.module.scss";
import { getMBCColorForValue, getMBCColorName } from "../../utills/CommonFuncs";

export default function TagDescription() {
  let array = [4, 10, 14, 19, 25, 30];

  return (
    <div style={{ display: "flex" }}>
      <p className={styles.ct3}>Overview</p>
      <Space
        size={"large"}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {array?.map((i) => (
          <div
            key={i}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tag
              style={{
                height: 14,
                width: 14,
                border: "none",
                backgroundColor: getMBCColorForValue(i),
              }}
            />
            <p>{getMBCColorName(i)}</p>
          </div>
        ))}
      </Space>
    </div>
  );
}
