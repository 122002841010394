import { constant } from "../constant";

const initialState = {
  uniqueId: null,
};

const ConfirmBookingDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.SET_CONFIRM_BOOKING_DATA:
      //  Object.assign(state, { ...action.payload });
      // return state;
      return {
        ...state,
        ...action.payload,
      };
    case constant.CLEAN_CONFIRM_BOOKING_DATA:
      return initialState;
    case constant.SELECT_DATE_TIME_OPEN:
      return {
        ...state,
        uniqueId: action.uniqueId,
      };
    default:
      return state;
  }
};

export default ConfirmBookingDataReducer;
