import http from "./config/MbcCLient";

export default class MbcApi {
  static async getMbcAvailability() {
    const response = await http.get("public/mbc/availability");
    return response;
  }
  static async getCheckInData() {
    const response = await http.get("public/mbc/checkin-screens-questions");
    return response;
  }
  static async putCheckInData(id, payload) {
    const response = await http.put(`public/mbc/submit-answers/${id}`, payload);
    return response;
  }
  static async closeCheckInMbc(id) {
    const response = await http.post(
      `/public/mbc/submit-answers/${id}/checkout`
    );
    return response;
  }
  static async getMyJourneyGraph(payload) {
    const response = await http.get("/public/mbc/my-journey-graph",payload);
    return response;
  }
}
