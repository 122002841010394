import { constant } from "../constant";

const initialState = {
  loading: true,
  consentFormStatus: true,
  errMsg: "",
};

const IntakeFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_CONSENT_FORM_STATUS:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_CONSENT_FORM_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        consentFormStatus: action.payload,
        errMsg: "",
      };

    case constant.GET_CONSENT_FORM_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
        consentFormStatus: false,
      };
    default:
      return state;
  }
};

export default IntakeFormReducer;
