export const API_BASE_URL =
  process.env.REACT_APP_BASE_API_URL || "https://stageapi.divethru.com";
export const REACT_APP_ENV = process.env.REACT_APP_ENV || "stage";
export const SQUARE_APP_ID = process.env.REACT_APP_SQUARE_APP_ID;
export const SQUARE_LOCATION_ID = process.env.REACT_APP_SQUARE_LOCATION_ID;
export const SQUARE_URL = process.env.REACT_APP_SQUARE_URL;
export const META_FACEBOOK_NAME = process.env.REACT_APP_META_FACEBOOK_NAME;
export const META_FACEBOOK_CONTENT =
  process.env.REACT_APP_META_FACEBOOK_CONTENT;
export const META_PIXEL_ID = process.env.REACT_APP_PIXEL_ID;
export const API_MBC_URL = "https://haoopknq0h.execute-api.ca-central-1.amazonaws.com/dev";
