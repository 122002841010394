import { constant } from "../constant";

const initialState = {
  loading: true,
  result: {},
  errMsg: "",
  count:0,
};

const PreAppointmentDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_PRE_APPOINTMENT_DATA:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_PRE_APPOINTMENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.result,
        errMsg: "",
      };
    
    case constant.GET_PRE_APPOINTMENT_DATA_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_PRE_APPOINTMENT_DATA:
      return {
        ...state,
        loading: false,
        result: {},
        count:0,
        errMsg: "",
      };
    default:
      return state;
  }
};

export default PreAppointmentDataReducer;
