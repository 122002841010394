import http from "./config/NetworkClient";

export default class TherapyApi {
  static async getTherapyIndex() {
    const response = await http.get("/api/v3/therapy-index?limit=3");
    return response;
  }
  static async getAppointmentList(payload) {
    const response = await http.get("/api/v3/appointments", payload);
    return response;
  }

  static async getTherapistProfile(id, submission_id = null) {
    let url;
    const locationId = window.localStorage.getItem("locationId");

    if (submission_id && !locationId) {
      url = `/api/v3/instructor-profile/${id}?submission_id=${submission_id}`;
    } else if (!submission_id && locationId) {
      url = `/api/v3/instructor-profile/${id}?studio_location_id=${locationId}`;
    } else if (submission_id && locationId) {
      url = `/api/v3/instructor-profile/${id}?submission_id=${submission_id}&studio_location_id=${locationId}`;
    } else {
      url = `/api/v3/instructor-profile/${id}`;
    }
    const response = await http.getWithoutAuth(url);

    return response;
  }
}
