const googleTagFire = (buttonName, action, eventData) => {
  const payload = {
    clickedEventActionName: buttonName,
    clickedEventName: action ?? "Button-Click",
    clickedEventResponse: eventData ?? null,
  };
  window?.dataLayer.push(payload);
};

export default googleTagFire;
