export const Avatar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M14.9997 20.1923C18.5046 20.1923 21.3459 17.351 21.3459 13.8462C21.3459 10.3413 18.5046 7.5 14.9997 7.5C11.4948 7.5 8.65356 10.3413 8.65356 13.8462C8.65356 17.351 11.4948 20.1923 14.9997 20.1923Z"
        fill="#667283"
      />
      <path
        d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19971 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649927 12.0006 -0.290551 15.0166 0.288227 17.9264C0.867006 20.8361 2.29562 23.5088 4.3934 25.6066C6.49119 27.7044 9.16393 29.133 12.0736 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4811 27.7229 25.8238 25.8003 27.472 23.3335C29.1203 20.8668 30 17.9667 30 15C29.9924 11.0241 28.4096 7.21321 25.5982 4.40182C22.7868 1.59042 18.9759 0.007624 15 0ZM24.4904 23.4231C23.5564 22.0833 22.3528 20.9534 20.9567 20.1058C19.3538 21.6414 17.2198 22.4987 15 22.4987C12.7802 22.4987 10.6462 21.6414 9.04327 20.1058C7.64722 20.9534 6.44363 22.0833 5.50962 23.4231C3.88526 21.5939 2.82411 19.3343 2.45389 16.9162C2.08367 14.498 2.42014 12.0244 3.42282 9.79305C4.4255 7.56168 6.05165 5.6676 8.10558 4.33878C10.1595 3.00995 12.5537 2.303 15 2.303C17.4463 2.303 19.8405 3.00995 21.8944 4.33878C23.9484 5.6676 25.5745 7.56168 26.5772 9.79305C27.5799 12.0244 27.9163 14.498 27.5461 16.9162C27.1759 19.3343 26.1147 21.5939 24.4904 23.4231Z"
        fill="#667283"
      />
    </svg>
  );
};
