import { put } from "redux-saga/effects";
import { QuestionnaireApi } from "../api";
import { constant } from "../constant";
// import { constant } from "../Constant";
// workers
function* getQuestionnaireSagaAction(param) {
  try {
    const res = yield QuestionnaireApi.getQuestionnaire();
    if (res.status === 200) {
      const payload = {
        type: constant.GET_QUESTIONNAIRE_SUCCESS,
        questionnaire: res.data.forms,
        count: res.data.count,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_QUESTIONNAIRE_FAILED,
        errMsg: "Invalid questionnaire",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_QUESTIONNAIRE_FAILED,
        errMsg: error.data.detail,
      };
      yield put(payload);
    }
  }
}

export { getQuestionnaireSagaAction };
