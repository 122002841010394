import { constant } from "../constant";

const initialState = {
  loading: true,
  mbcSubmitId: null,
  sliderData: [],
  questionData: [],
  title: "",
  des: "",
};

const MbcReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_MBC_DATA:
      return {
        ...state,
        loading: true,
        mbcSubmitId: null,
      };
    case constant.GET_MBC_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        mbcSubmitId: action.data.mbcSubmitId,
        sliderData: action.data.sliderData,
        questionData: action.data.questionData,
        title: action.data.title,
        des: action.data.des,
      };

    case constant.GET_MBC_DATA_FAILED:
      return {
        ...state,
        loading: false,
        mbcSubmitId: null,
        sliderData: [],
        questionData: [],
        errMsg: action.errMsg,
        title: "",
        des: "",
      };
    default:
      return state;
  }
};

export default MbcReducer;
