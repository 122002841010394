import { put } from "redux-saga/effects";
import { BookingApi } from "../api";
import { constant } from "../constant";
// import { constant } from "../Constant";
// workers
function* getPreAppointmentDataSagaAction(param) {
  try {
    const res = yield BookingApi.getPreAppointmentData(
      param?.studio_location_id,
      param?.instructor_id
    );
    if (res.status === 200) {
      const payload = {
        type: constant.GET_PRE_APPOINTMENT_DATA_SUCCESS,
        result: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_PRE_APPOINTMENT_DATA_FAILED,
        errMsg: res?.data?.detail ?? "Invalid pre appointment data",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_PRE_APPOINTMENT_DATA_FAILED,
        errMsg: error.data,
      };
      yield put(payload);
    }
  }
}

export { getPreAppointmentDataSagaAction };
