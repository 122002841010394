import { put } from "redux-saga/effects";
import { IntakeFormApi } from "../api";
import { constant } from "../constant";

// workers
function* getConsentFormStatus() {
  try {
    const res = yield IntakeFormApi.getIntakeFormStatus();
    if (res.status === 200) {
      const payload = {
        type: constant.GET_CONSENT_FORM_STATUS_SUCCESS,
        payload: res.data.consent_form_is_pending,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_CONSENT_FORM_STATUS_FAILED,
        errMsg: "Invalid consent form status",
        payload: false,
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_CONSENT_FORM_STATUS_FAILED,
        errMsg: error.data.detail ?? "Invalid consent form status",
        payload: false,
      };
      yield put(payload);
    }
  }
}

export { getConsentFormStatus };
