import { constant } from "../constant";

const initialState = {
  loading: true,
  questionnaire: [],
  errMsg: "",
  count:0,
  details: {},
};

const QuestionnaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_QUESTIONNAIRE:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        loading: false,
        questionnaire: action.questionnaire,
        count:action.count,
        errMsg: "",
      };
    
    case constant.GET_QUESTIONNAIRE_FAILED:
      return {
        ...state,
        loading: false,
        errMsg: action.errMsg,
      };
    case constant.CLEAN_QUESTIONNAIRE:
      return {
        ...state,
        loading: false,
        questionnaire: [],
        count:0,
        errMsg: "",
      };
    default:
      return state;
  }
};

export default QuestionnaireReducer;
