import { Modal } from "antd";
import React from "react";
import styles from "./styles.module.scss";
import { CloseButton } from "../../assets/svg";

const NeedHelpStaticModal = ({ showModal, setShowModal }) => {
  return (
    <Modal
      open={showModal}
      centered={true}
      closable={false}
      width={486}
      footer={<></>}
    >
      <div className={styles.crossBtn} onClick={() => setShowModal(false)}>
        <CloseButton height={"30"} width={"30"} />
      </div>

      <p className={styles.title}>Need Help?</p>
      <div style={{ paddingRight: 20 }}>
        <p className={styles.des} style={{ paddingTop: 20, paddingBottom: 0 }}>
          Please note that your responses are not monitored. Discuss your
          results with your therapist in your upcoming session. If you need
          support before then, access the 24/7 resources below.
        </p>
        <p className={styles.des} style={{ paddingTop: 20, paddingBottom: 0 }}>
          Suicide Crisis Hotline: If you are thinking about suicide, please text
          or call{" "}
          <span style={{ color: "#000", fontWeight: "bold" }}>9-8-8</span>.
        </p>
        <p className={styles.des} style={{ paddingTop: 20, paddingBottom: 0 }}>
          Hope for Wellness: For Indigenous people seeking emotional support,
          crisis intervention, or referrals to community-based services, call{" "}
          <span style={{ color: "#000", fontWeight: "bold" }}>
            1-855-242-3310
          </span>{" "}
          or{" "}
          <span style={{ color: "#000", fontWeight: "bold" }}>chat online</span>
          .
        </p>
      </div>
    </Modal>
  );
};

export default NeedHelpStaticModal;
