import { constant } from "../constant";

export const getTherapyIndex = () => {
  return {
    type: constant.GET_THERAPY_INDEX,
  };
};

export const getMbcData = () => {
  return {
    type: constant.GET_MBC_DATA,
  };
};
