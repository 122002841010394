import {
  Button,
  Checkbox,
  Col,
  Row,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import CardList from "../CardList";
import { SQUARE_APP_ID } from "../../store/api/config/config";
import { SettingsApi } from "../../store/api";
import { showMessege } from "../CustomMessage";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "../../store";
import { useNavigate } from "react-router-dom";
import { SafetyOutlined } from "@ant-design/icons";

export default function AccountSetupPaymentModal({
  cardList,
  onChangeDefault,
  deletCardHandle,
  setvisibleAddNewCardModal,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setselectedTab] = useState(1);
  const [card, setcard] = useState();
  const [isDefault, setisDefault] = useState(true);
  const [loading, setloading] = useState(false);
  const [isConsentChecked, setisConsentChecked] = useState(false);
  const therapyReducer = useSelector((state) => state.TherapyReducer);

  useEffect(() => {
    if (selectedTab === 2 || cardList?.length === 0) {
     
      window.document.getElementById("card").innerHTML = "<div></div>";

      setTimeout(() => {
        initializeCard();
      }, 400);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const initializeCard = async () => {
    const payments = await window?.Square?.payments(
      SQUARE_APP_ID
      // SQUARE_LOCATION_ID
    );

    // if (card === undefined) {
      const card = await payments?.card();
      await card?.attach("#card");
      setcard(card);
    // }
  };

  const onPressSave = async () => {
    setloading(true);
    const result = await card?.tokenize();
    if (result?.status === "Invalid") {
      result?.errors?.map((er) => {
        setloading(false);
        showMessege("error", er?.message);
        return null;
      });
    } else {
      createNewCard(result?.token);
    }
    // dispatch(ActionCreators.confirmBookingAction(bookingData));
  };

  const createNewCard = async (token) => {
    let payload = {
      default: isDefault,
      card_token: token,
    };

    const response = await SettingsApi.addNewCard(payload);
    if (response?.status === 201) {
      // message.success("Card Successfully added");
      showMessege("success", "Card Successfully added");
      onPressProceed();

      //   setvisibleAddNewCardModal(false);
      setloading(false);
    } else {
      // message.error(response?.data?.detail);
      showMessege("error", response?.data?.detail);
      //   setloading(false);
    }
  };

  const onPressProceed = () => {
    dispatch(ActionCreators.getTherapyIndex());

    if (therapyReducer?.result?.patient_profile?.instructor) {
      window.localStorage.setItem(
        "instructor_id",
        therapyReducer?.result?.patient_profile?.instructor?.id
      );
      navigate("/therapy");
    } else if (therapyReducer?.result?.appointment) {
      navigate("/therapy");
    } else if (therapyReducer?.result?.recommended_instructors?.length > 0) {
      navigate("/recommended-list");
    } else {
      navigate("/matching-tools");
    }
  };

  if (cardList?.length > 0) {
    return (
      <Space direction="vertical">
        <Typography.Paragraph
          style={{
            fontFamily: "Archivo",
            textAlign: "center",
            color: "#667283",
            marginTop:'5vh',
            marginBottom:'2vh'
          }}
        >
          To book an appointment, we require a credit card on file. But don't
          worry — we don't automatically charge it! <br /> <br /> After your
          session is complete, you can choose to pay with the card on file,
          through your benefits or another option. If you don't own a credit
          card, please contact our studio and they will assist you from there.
        </Typography.Paragraph>
        <Space style={{ display: "flex", justifyContent: "center" ,marginBottom: '3vh'}}>
          <Button
            size="large"
            type={selectedTab === 1 ? "default" : "text"}
            style={{ fontSize: 13 }}
            onClick={() => {
              setselectedTab(1);
            }}
          >
            Your Cards
          </Button>
          <Button
            size="large"
            type={selectedTab === 2 ? "default" : "text"}
            style={{ fontSize: 13 }}
            onClick={() => {
              setselectedTab(2);
            }}
          >
            Add New Card
          </Button>
        </Space>

        <div >
          {selectedTab === 1 ? (
            <>
              {cardList &&
                cardList?.length > 0 &&
                cardList?.map((item, key) => {
                  return (
                    <CardList
                      item={item}
                      deletCardHandle={deletCardHandle}
                      onChangeDefault={onChangeDefault}
                    />
                  );
                })}
              <Row gutter={16}>
                <Col span={12}>
                  <Button
                    size="large"
                    type={"default"}
                    style={{ fontSize: 16 }}
                    onClick={() => {
                      setvisibleAddNewCardModal(false);
                    }}
                    block
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    block
                    size="large"
                    type={"primary"}
                    style={{ fontSize: 16 }}
                    onClick={() => {
                      onPressProceed();
                    }}
                  >
                    Proceed
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div id="card"></div>
              {card && (
                <>
                  <Space direction="vertical" size={"large"}>
                    <Col
                      span={24}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography.Text>Set as default card</Typography.Text>
                      <Switch
                        defaultChecked
                        onChange={(data) => {
                          setisDefault(data);
                        }}
                      />
                    </Col>
                    <Col span={24}>
                      <Col
                        span={24}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            <Checkbox
                              defaultChecked={isConsentChecked}
                              onChange={(data) => {
                                setisConsentChecked(!isConsentChecked);
                              }}
                            />
                          </Col>
                          <Col span={22}>
                            <Typography.Text
                              style={{
                                fontSize: 14,
                                fontFamily: "Archivo",
                                color: "#667283",
                              }}
                            >
                              I understand that if I cancel my appointment with
                              less than 48 hours notice or miss a scheduled
                              session, I will be charged the full fee for the
                              session.
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                        // alignItems: "center",
                        // justifyContent: "center",
                      }}
                    >
                      <SafetyOutlined
                        style={{
                          fontSize: 16,
                          color: "#18B54D",
                          marginRight: 6,
                        }}
                      />
                      <Typography.Text
                        style={{
                          fontSize: 14,
                          color: "#18B54D",
                          fontFamily: "Archivo",
                          fontWeight: "600",
                        }}
                      >
                        Secure Payment
                      </Typography.Text>
                    </Col>
                  </Space>
                  <Row gutter={16} style={{ marginTop: 20 }}>
                    <Col span={12}>
                      <Button
                        type="default"
                        size="large"
                        block
                        style={{
                          // backgroundColor: "#1BCDB7",
                          // color: "#fff",
                          // borderColor: "#1BCDB7",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          setvisibleAddNewCardModal(false);
                        }}
                        // loading={loading}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Tooltip
                        title={
                          !isConsentChecked &&
                          "Make sure you’ve accepted our terms and policies"
                        }
                      >
                        <Button
                          type={isConsentChecked ? "primary" : "default"}
                          size="large"
                          disabled={!isConsentChecked}
                          block
                          style={{
                            // backgroundColor: "#1BCDB7",
                            // color: "#fff",
                            // borderColor: "#1BCDB7",
                            fontWeight: "bold",
                          }}
                          onClick={onPressSave}
                          loading={loading}
                        >
                          Save
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </div>
      </Space>
    );
  } else {
    return (
      <>
        <Typography.Title
          style={{
            fontFamily: "Archivo",
            fontSize: 14,
            fontWeight: 400,
            textAlign: "center",
            color: "#667283",
            letterSpacing: 0.4,
            marginBottom: '5vh',
            marginTop:'5vh'
          }}
        >
          To book an appointment, we require a credit card on file. But don't
          worry — we don't automatically charge it! <br /> <br /> After your
          session is complete, you can choose to pay with the card on file,
          through your benefits or another option. If you don't own a credit
          card, please contact our studio and they will assist you from there.
        </Typography.Title>
        <div  id="card"></div>
        {card && (
          <>
            <Space direction="vertical" size={"large"}>
              <Col
                span={24}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography.Text style={{ fontSize: 12 }}>
                  Set as default card
                </Typography.Text>
                <Switch
                  defaultChecked
                  onChange={(data) => {
                    setisDefault(data);
                  }}
                />
              </Col>
              <Col
                span={24}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* <Row>
                  <Col span={2}> */}
                <Space
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Checkbox
                    defaultChecked={false}
                    onChange={(data) => {
                      setisConsentChecked(!isConsentChecked);
                    }}
                  />
                  <Typography.Text
                    style={{
                      fontSize: 14,
                      fontFamily: "Archivo",
                      color: "#667283",
                    }}
                  >
                    I understand that if I cancel my appointment with less than
                    48 hours notice or miss a scheduled session, I will be
                    charged the full fee for the session.
                  </Typography.Text>
                </Space>

                {/* </Col>
                  <Col span={22}> */}

                {/* </Col>
                </Row> */}
              </Col>
              <Col
                span={24}
                style={{
                  textAlign: "center",
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
              >
                <SafetyOutlined
                  style={{
                    fontSize: 16,
                    color: "#18B54D",
                    marginRight: 6,
                  }}
                />
                <Typography.Text
                  style={{
                    fontSize: 14,
                    color: "#18B54D",
                    fontFamily: "Archivo",
                    fontWeight: "600",
                  }}
                >
                  Secure Payment
                </Typography.Text>
              </Col>
            </Space>
            <Col
              span={24}
              style={{
                // display: "flex",
                // justifyContent: "center",
                marginTop: 20,
              }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Button
                    type="default"
                    size="large"
                    block
                    style={{
                      // backgroundColor: "#1BCDB7",
                      // color: "#fff",
                      // borderColor: "#1BCDB7",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      setvisibleAddNewCardModal(false);
                    }}
                    // loading={loading}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12}>
                  <Tooltip
                    title={
                      !isConsentChecked &&
                      "Make sure you’ve accepted our terms and policies"
                    }
                  >
                    <Button
                      type={isConsentChecked ? "primary" : "default"}
                      size="large"
                      disabled={!isConsentChecked}
                      block
                      style={{
                        // backgroundColor: "#1BCDB7",
                        // color: "#fff",
                        // borderColor: "#1BCDB7",
                        fontWeight: "bold",
                      }}
                      onClick={onPressSave}
                      loading={loading}
                    >
                      Save
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </>
        )}
      </>
    );
  }
}
