import http from "./config/NetworkClient";

export default class IntakeFormApi {
  static async getIntakeFormStatus() {
    const response = await http.get("/api/v3/user_consent_form_status");
    return response;
  }

  static async getFormList(page = 1, limit = 5) {
    const response = await http.get(
      `/api/v3/user_consent_forms?page=${page}&limit=${limit}`
    );
    return response;
  }

  static async getFormDetails(id) {
    const response = await http.get(`/api/v3/user_consent_form/${id}`);
    return response;
  }

  static async updateIntakeForm(payload, id) {
    const response = await http.put(`/api/v3/user_consent_form/${id}`, payload);
    return response;
  }

  static async downloadedFormStatus(id) {
    const response = await http.put(
      `/api/v3/user-consent-forms/${id}/download`
    );
    return response;
  }

  static async getSignatureImg(url) {
    const response = await http.get(
      `/api/v3/cliniko_attachment_response?url=${url}`
    );
    return response;
  }

  static async uploadSignature(payload) {
    const response = await http.filePost(
      `/api/v3/cliniko_file_attachment?file_type=SIGNATURE`,
      payload
    );
    return response;
  }

  static async downloadForm(id) {
    const response = await http.get(
      `/api/v3/download_consent_form/pdf?user_consent_form_id=${id}`
    );
    return response;
  }

  static async uploadForm(payload, type) {
    const response = await http.filePost(
      `/api/v3/cliniko_file_attachment?file_type=${type}`,
      payload
    );
    return response;
  }

  static async deleteImg(id) {
    const response = await http.put(
      `api/v3/cliniko_file_archive?user_consent_form_id=${id}`
    );
    return response;
  }
}
