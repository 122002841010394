export const ThreeDots = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="18"
      viewBox="0 0 4 18"
      fill="none"
    >
      <circle cx="2" cy="2" r="2" fill="#667283" />
      <circle cx="2" cy="9" r="2" fill="#667283" />
      <circle cx="2" cy="16" r="2" fill="#667283" />
    </svg>
  );
};
