import http from "./config/NetworkClient";

export default class BookingApi {
  static async getPreAppointmentData(studio_location_id, instructor_id) {
    let response;
    if (studio_location_id) {
      response = await http.get(
        `/api/v3/pre-appointment-data?studio_location_id=${studio_location_id}&instructor_id=${instructor_id}`
      );
    } else {
      response = await http.get(
        `/api/v3/pre-appointment-data?instructor_id=${instructor_id}`
      );
    }

    return response;
  }

  static async getAppointmentTypes(instructor_id, locationId, payload) {
    const response = await http.get(
      `/api/v3/instructors/${instructor_id}/locations/${locationId}/appointment_types`,
      payload
    );
    return response;
  }

  static async getInstructorAvailabiltyData(
    instructor_id,
    locationId,
    appointment_type_id,
    payload
  ) {
    const response = await http.get(
      `/api/v3/instructors/${instructor_id}/locations/${locationId}/appointment_types/${appointment_type_id}/available_times`,
      payload
    );
    return response;
  }

  // static async getInstructorAvailabiltyData(
  //   instructor_id,
  //   studio_location,
  //   payload
  // ) {
  //   // console.log(payload);
  //   const response = await http.get(
  //     `/api/v3/instructor-availability/${instructor_id}/studio_location/${studio_location}`,
  //     payload
  //   );
  //   return response;
  // }

  static async confirmBooking(payload) {
    const response = await http.post("/api/v3/confirm-appointment", payload);
    return response;
  }

  static async getAnotherPatients(payload) {
    const response = await http.get("/api/v3/user-relatives");
    return response;
  }

  static async addAnotherPatient(payload) {
    const response = await http.post("/api/v3/user-relative", payload);
    return response;
  }

  static async updatePatient(id, payload) {
    const response = await http.put(`/api/v3/user-relative/${id}`, payload);
    return response;
  }

  static async getAssignFormData(payload) {
    const response = await http.post("/api/v3/assign-form-submission", payload);
    return response;
  }

  static async assignManualFormData(payload) {
    const response = await http.post(
      "/api/v3/assign-manual-submission",
      payload
    );
    return response;
  }

  static async cancelAppointment(id) {
    const response = await http.put(`/api/v3/appointments/${id}/cancel`);
    return response;
  }

  static async getPreConfirmingAppointmentData(
    appointment_type_id,
    instructor_id
  ) {
    const response = await http.get(
      `/api/v3/pre-confirm-appointment-data?appointment_type_id=${appointment_type_id}&instructor_id=${instructor_id}`
    );
    return response;
  }

  static async getSupportInfo() {
    const response = await http.get(`api/v3/support-info`);
    return response;
  }

  static async getCancellationMessage(id) {
    const response = await http.get(
      `api/v3/appointments/${id}/cancellation-message`
    );
    return response;
  }

  static async getSelectDateTimeData(id) {
    const response = await http.get(`/api/v3/waitlist/user_booking_info/${id}`);
    return response;
  }
}
