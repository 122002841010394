import React, { useCallback, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { Card, Carousel, Col, Modal, Row, Space } from "antd";
import MbcAvatar from "../../assets/svg/MbcAvatar";
import { CloseButton } from "../../assets/svg";
import SliderFlag from "../../assets/svg/SliderFlag";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../Loader";

const DotCircle = () => {
  const circleStyle = {
    height: "18px",
    width: "18px",
    borderRadius: "50%",
    backgroundColor: "#ED545C",
    position: "absolute",
    top: -9,
    right: 20,
    zIndex: 999,
  };

  return <div style={circleStyle}></div>;
};

const contentStyle = {
  margin: 0,
  height: "100%",
  width: "100%",
  zIndex: -999,
};

const MbcCard = () => {
  const navigate = useNavigate();
  const carouselRef = useRef();
  const state = useSelector((state) => state.MbcReducer);
  const arr = state?.sliderData;
  const [showCarousel, setShowCarousel] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSubmit = useCallback(() => {
    if (state?.sliderData?.length > 0) {
      setShowCarousel(true);
    } else {
      navigate("/mbc-questionaire");
    }
  }, [state?.sliderData?.length]);

  const handleNext = () => {
    if (currentSlide === arr.length - 1) {
      navigate("/mbc-questionaire");
    } else {
      carouselRef.current.next();
    }
  };

  const handlePrev = () => {
    if (currentSlide === 0) return;
    carouselRef.current.prev();
  };

  if (!state?.mbcSubmitId && !state?.loading) return;

  if (state.loading) {
    return <Loader />;
  }

  return (
    <Card
      style={{
        border: "1px solid #DEE3EB",
        borderRadius: 10,
        marginTop: 30,
        marginBottom: 30,
        position: "relative",
        zIndex: 0,
        textAlign: "left",
      }}
    >
      <DotCircle />

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={18} lg={18} xl={16}>
          <Space>
            <div className={styles.MbcAvatar}>
              <MbcAvatar />
            </div>
            <div style={{ marginLeft: 20 }}>
              <p className={styles.mbcTitle}>{state?.title}</p>
              <p className={styles.mbcDes}>{state?.des}</p>
            </div>
          </Space>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={7}
          className={styles.mbcBtnContainer}
        >
          <div className={styles.mbcBtn} onClick={handleSubmit}>
            {"Yes, Let’s Start"}
          </div>
        </Col>
      </Row>
      <Modal
        open={showCarousel}
        centered={true}
        closable={false}
        width={560}
        footer={<></>}
      >
        <div className={styles.sliderContainer}>
          <div
            onClick={() => setShowCarousel(false)}
            style={{
              position: "absolute",
              right: 20,
              top: 20,
              cursor: "pointer",
              zIndex: 999,
            }}
          >
            <CloseButton height={"30"} width={"30"} />
          </div>
          <Carousel
            beforeChange={(e, f) => setCurrentSlide(f)}
            adaptiveHeight={true}
            lazyLoad="progressive"
            ref={carouselRef}
          >
            {arr.map((item, i) => (
              <div style={contentStyle} key={i}>
                <p className={styles.sliderTitle}>{item?.title}</p>
                <p className={styles.sliderDes}>{item?.description}</p>
                {item?.points?.length > 0 &&
                  item?.points.map((a, j) => (
                    <div key={j}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <SliderFlag />
                        <p
                          className={styles.sliderDes}
                          style={{
                            textAlign: "left",
                            paddingLeft: 10,
                            margin: 0,
                          }}
                        >
                          {a}
                        </p>
                      </div>
                    </div>
                  ))}
                {item?.paragraph_1 && (
                  <p className={styles.sliderDes}>{item?.paragraph_1}</p>
                )}
                {item?.paragraph_2 && (
                  <p className={styles.sliderDes}>{item?.paragraph_2}</p>
                )}
              </div>
            ))}
          </Carousel>
          <div className={styles.sliderBtnContainer}>
            <div
              className={styles.sliderBack}
              style={{
                color: currentSlide === 0 ? "#c2cce1" : "#667283",
                cursor: currentSlide === 0 ? "not-allowed" : "pointer",
              }}
              onClick={() => handlePrev()}
            >
              Back
            </div>
            <div className={styles.sliderNext} onClick={() => handleNext()}>
              {currentSlide === arr.length - 1 ? "Let’s Check In Now" : "Next"}
            </div>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

export default MbcCard;
