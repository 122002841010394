import { Button, Carousel, Space } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import MbcCard from "../../components/mbc/MbcCard";
import { useDispatch, useSelector } from "react-redux";
import { CloseButton } from "../../assets/svg";
import SliderFlag from "../../assets/svg/SliderFlag";
import { ActionCreators } from "../../store";
// import { useSelector } from "react-redux";
// import { useRouter } from "next/router";

const contentStyle = {
  margin: 0,
  height: "100%",
  width: "100%",
  zIndex: -999,
};


export default function StartCheckInModal({ setopenStartCheckIn, modalData }) {
  const navigate = useNavigate();
  const carouselRef = useRef();
  const state = useSelector((state) => state.MbcReducer);
  const arr = state?.sliderData;
  const [showCarousel, setShowCarousel] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(ActionCreators.getMbcData());
  }, [])


  const onPressStart = useCallback(() => {
    if (state?.sliderData?.length != 0) {
      setShowCarousel(true);
    } else {
      navigate("/mbc-questionaire");
    }
  }, [state?.sliderData?.length]);
  const handleNext = () => {
    if (currentSlide === arr.length - 1) {
      navigate("/mbc-questionaire");
    } else {
      carouselRef.current.next();
    }
  };

  const handlePrev = () => {
    if (currentSlide === 0) {
      setShowCarousel(false)
    }
    carouselRef.current.prev();
  };



  if(showCarousel){
    return (
      <div className={styles.sliderContainer}>
        {/* <div
          onClick={() => setShowCarousel(false)}
          style={{
            position: "absolute",
            right: 20,
            top: 20,
            cursor: "pointer",
            zIndex: 999,
          }}
        >
          <CloseButton height={"30"} width={"30"} />
        </div> */}
        <Carousel
          beforeChange={(e, f) => setCurrentSlide(f)}
          adaptiveHeight={true}
          lazyLoad="progressive"
          ref={carouselRef}
        >
          {arr.map((item, i) => (
            <div style={contentStyle} key={i}>
              <p className={styles.sliderTitle}>{item?.title}</p>
              <p className={styles.sliderDes}>{item?.description}</p>
              {item?.points?.length > 0 &&
                item?.points.map((a, j) => (
                  <div key={j}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <SliderFlag />
                      <p
                        className={styles.sliderDes}
                        style={{
                          textAlign: "left",
                          paddingLeft: 10,
                          margin: 0,
                        }}
                      >
                        {a}
                      </p>
                    </div>
                  </div>
                ))}
              {item?.paragraph_1 && (
                <p className={styles.sliderDes}>{item?.paragraph_1}</p>
              )}
              {item?.paragraph_2 && (
                <p className={styles.sliderDes}>{item?.paragraph_2}</p>
              )}
            </div>
          ))}
        </Carousel>
        <div className={styles.sliderBtnContainer}>
          <div
            className={styles.sliderBack}
            style={{
              // color: currentSlide === 0 ? "#c2cce1" : "#667283",
              // cursor: currentSlide === 0 ? "not-allowed" : "pointer",
            }}
            onClick={() => handlePrev()}
          >
            Back
          </div>
          <div className={styles.sliderNext} onClick={() => handleNext()}>
            {currentSlide === arr.length - 1 ? "Let’s Check In Now" : "Next"}
          </div>
        </div>
      </div>
    );
  }



  return (
    <div style={{}}>
      <Space direction="vertical" size={"small"} style={{ width: "100%" }}>
        <p className={styles.ct2} style={{ marginBottom: 0 }}>
          Start your first check-in?
        </p>
        <p className={styles.s2} style={{ color: "#667283", marginBottom: 0 }}>
          At DiveThru, we conduct mental health &quot;Check-ins&quot;, also
          known as Measurement-Based Care. Your therapist will explain more in
          your first session. If you consent, you&apos;ll see Check-ins to
          complete here 24 hours before each appointment.
        </p>
      </Space>

      <Space
        style={{
          display: "flex",

          justifyContent: "flex-end",
        }}
      >
        <Button
          type="default"
          onClick={() => {
            setopenStartCheckIn(false);
          }}
          style={{ marginTop: 20 }}
        >
          <span style={{ color: "#000" }}>No</span>
        </Button>
        <Button
          type="default"
          onClick={onPressStart}
          style={{
            marginTop: 20,
            backgroundColor: "#883DBF",
            color: "white",
            border: "1px solid #883DBF",
          }}
        >
          Yes, Start Now
        </Button>
      </Space>
    </div>
  );
}
