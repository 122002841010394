import { constant } from "../constant";

export const getPreAppointmentData = (studio_location_id, instructor_id) => {
  return {
    type: constant.GET_PRE_APPOINTMENT_DATA,
    instructor_id: instructor_id,
    studio_location_id: studio_location_id,
  };
};

export const getInstructorAvailablity = (
  instructor_id,
  studio_location,
  appointment_type_id,
  payload
) => {
  return {
    type: constant.GET_INSTRUCTOR_AVAILABLITY,
    instructor_id: instructor_id,
    studio_location: studio_location,
    appointment_type_id: appointment_type_id,
    payload: payload,
  };
};

export const setConfirmBookingDataAction = (payload) => {
  return {
    type: constant.SET_CONFIRM_BOOKING_DATA,
    payload: payload,
  };
};

export const confirmBookingAction = (payload) => {
  return {
    type: constant.CONFIRM_BOOKING,
    payload: payload,
  };
};

export const newSessionPreCleanUp = () => {
  return {
    type: constant.NEW_SESSION_PRE_CLEANUP,
  };
};

export const setUniqueId = (uniqueId) => {
  return {
    type: constant.SELECT_DATE_TIME_OPEN,
    uniqueId: uniqueId,
  };
};
