import { Card, Col, Row, Typography } from "antd";
import React from "react";
import styles from "./styles.module.scss";

export default function MultiSelect({
  data,
  setmultiSelectedOptions,
  multiSelectedOptions,
}) {
  const onSelectItem = (option) => {
    let index = multiSelectedOptions?.findIndex(
      (d) => d?.name === option?.name
    );
    if (index > -1) {
      // console.log("item exist");
      let array = [...multiSelectedOptions];
      array.splice(index, 1);
      setmultiSelectedOptions(array);
    } else {
      // console.log("item not exist");
      setmultiSelectedOptions((data) => [...multiSelectedOptions, option]);
    }
  };

  const getClass = (option) => {
    let selected = multiSelectedOptions?.find((i) => i?.name === option?.name);

    if (selected) {
      if (data?.design_type === "SELECT") {
        return styles.selectedSingleLineMultiOption;
      } else {
        return styles.selectedMultiOption;
      }
    } else {
      if (data?.design_type === "SELECT") {
        return styles.unSelectedSignleLineMultiOption;
      } else {
        return styles.unSelectedMultiOption;
      }
    }
  };
  return (
    <div>
      <Row
        gutter={16}
        className={
          data?.design_type !== "SELECT" ? styles.multiOptionContainer : {}
        }
      >
        {data?.options?.map((option) => (
          <Col
            key={option?.name}
            xl={data?.design_type === "SELECT" ? 24 : 6}
            lg={data?.design_type === "SELECT" ? 24 : 8}
            sm={data?.design_type === "SELECT" ? 24 : 12}
            md={data?.design_type === "SELECT" ? 24 : 12}
            xs={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card
              className={getClass(option)}
              bodyStyle={{
                // paddingTop: 15,
                // paddingBottom: 15,
                display:'flex',
                justifyContent:'center',
                overflow: "hidden",
              }}
              onClick={() => {
                onSelectItem(option);
              }}
            >
              <Typography.Text
                className={styles.text}
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                {option?.name}
              </Typography.Text>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}
