import { constant } from "../constant";

export const logOutAction = () => {
  return {
    type: constant.LOGOUT,
  };
};

export const siderCondition = (param) => {
  return {
    type: constant.SIDER_CONDITION,
    payload: param,
  };
};

export const setFirebaseCred = (param) => {
  return {
    type: constant.FIREBASE_CREDENTIALS,
    payload: param,
  };
};

export const setUserInfoData = (param) => {
  return {
    type: constant.USER_PROFILE_INFO,
    payload: param,
  };
};

export const setInfo = (param) => {
  return {
    type: constant.USER_INFO,
    payload: param,
  };
};
export const setJwt = (jwt, loginStatus) => {
  return {
    type: constant.SET_JWT,
    jwt: jwt,
    loginStatus: loginStatus,
  };
};
export const setSettingsRoute = (param) => {
  return {
    type: constant.SET_SETTINGS_ROUTE,
    payload: param,
  };
};

export const setLoginLoader = (param) => {
  return {
    type: constant.SET_LOGIN_LOADER,
    payload: param,
  };
};

export const gotoReduceRate = (param) => {
  return {
    type: constant.REDUCE_RATE_OPEN,
    payload: param,
  };
};
