import { put } from "redux-saga/effects";
import { BookingApi } from "../api";
import { constant } from "../constant";
// import { constant } from "../Constant";
// workers
function* confirmBookingSagaAction(param) {
  try {
    const res = yield BookingApi.confirmBooking(param?.payload);
    if (res.status === 200) {
      const payload = {
        type: constant.CONFIRM_BOOKING_SUCCESS,
        result: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.CONFIRM_BOOKING_FAILED,
        errMsg: "Invalid booking data",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.CONFIRM_BOOKING_FAILED,
        errMsg: error.data,
      };
      yield put(payload);
    }
  }
}

function* newSessionPreCleanUpSagaAction() {
  let payload = {
    type: constant.CLEAN_CONFIRM_BOOKING_DATA,
  };
  yield put(payload);
  payload = {
    type: constant.CLEAN_INSTRUCTOR_AVAILABLITY,
  };
  yield put(payload);
  payload = {
    type: constant.CLEAN_CONFIRM_BOOKING,
  };
  yield put(payload);

 
}

export { confirmBookingSagaAction, newSessionPreCleanUpSagaAction };

