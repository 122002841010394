import React from "react";
import styles from "./styles.module.scss";
import { Col, Grid, Row, Switch } from "antd";
import amexCard from "../assets/amex.png";
import defaultCard from "../assets/defaultCard.png";
import interacCard from "../assets/interac.png";
import jcbCard from "../assets/JCB.png";
import masterCard from "../assets/mastercard.png";
import visaCard from "../assets/visa.png";

const { useBreakpoint } = Grid;

export default function CardList({
  onChangeDefault,
  deletCardHandle,
  item,
  loading,
}) {
  const screen = useBreakpoint();

  return (
    <div className={styles.cardView}>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col lg={6} xs={6} className={styles.cardInside}>
          <img
            src={
              item.card_brand === "VISA" || item.card_brand === "VISACARD"
                ? visaCard
                : item.card_brand === "MASTER" ||
                  item.card_brand === "MASTERCARD"
                ? masterCard
                : item.card_brand === "JCB" || item.card_brand === "JCBCARD"
                ? jcbCard
                : item.card_brand === "AMEX" ||
                  item.card_brand === "AMERICAN_EXPRESS"
                ? amexCard
                : item.card_brand === "INTERAC"
                ? interacCard
                : defaultCard
            }
            alt="Card"
            style={{ maxWidth: "70%", paddingRight: 10 }}
          />

          <p className={styles.text600}>
            {screen.xs && !screen?.lg
              ? item.card_brand?.slice(0, 10) + "..."
              : item.card_brand}
          </p>
        </Col>
        {item?.card_number && (
          <Col lg={6} xs={8} className={styles.text600}>
            {"****" + item?.card_number?.slice(-4)}
          </Col>
        )}
      </Row>
      <Row gutter={8}>
        <Col lg={18} xs={16}>
          <p className={styles.textGray}>Name on Card</p>
          <p className={styles.text600}>{item.cardholder_name ?? "N/A"}</p>
        </Col>
        <Col lg={6} xs={8}>
          <p className={styles.textGray}>Expiry Date</p>
          <p className={styles.text600}>
            {item.expire_month + "/" + item.expire_year}
          </p>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={18} xs={16} style={{ display: "flex", alignItems: "center" }}>
          <p className={styles.defaultText}>
            Set as default card{" "}
            <Switch
              className={styles.defaultSwitch}
              checked={item?.default}
              loading={loading}
              onChange={(data) => {
                onChangeDefault({
                  id: item?.id,
                  default: data,
                });
              }}
            />
          </p>
        </Col>
        <Col lg={6} xs={8} style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={() => deletCardHandle(item.id)}
            className={styles.cardButton}
          >
            Delete
          </button>
        </Col>
      </Row>
    </div>
  );
}
