import { Card, Typography } from "antd";
import React from "react";
import styles from "./styles.module.scss";

export default function SingleSelect({
  data,
  setsingleSelectedOption,
  singleSelectedOption,
}) {
  const onSelectItem = (option) => {
    setsingleSelectedOption([option]);
  };

  const getClass = (item) => {
    let selected = singleSelectedOption?.find((i) => i?.name === item?.name);

    if (selected) {
      return styles.selectedSingleOption;
    } else {
      return styles.unSelectedSingleOption;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {data?.options?.map((option) => (
        <Card
          key={option?.name}
          className={getClass(option)}
          bodyStyle={{ paddingTop: 15, paddingBottom: 15 }}
          onClick={() => {
            onSelectItem(option);
          }}
        >
          <Typography.Text
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {option?.name}
          </Typography.Text>
        </Card>
      ))}
    </div>
  );
}
