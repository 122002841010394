import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showMessege } from "../../components/CustomMessage";
import Loader from "../../components/Loader";
import { gotoReduceRate } from "../../store/action";
import { SettingsApi } from "../../store/api";
import NetworkClient from "../../store/api/config/NetworkClient";
import styles from "./styles.module.scss";
import googleTagFire from "../../utills/googleTagFire";

export default function ReduceRate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginStatus = useSelector((state) => state.AuthReducer.loginStatus); //store data

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [questions, setquestions] = useState([]);
  const [dateArray, setdateArray] = useState([]);
  const [alreadySubmitted, setalreadySubmitted] = useState(false);
  const [reduceRateFormId, setreduceRateFormId] = useState();

  useEffect(() => {
    if (loginStatus) {
      gerReduceRateStatus();
      dispatch(gotoReduceRate(false));
    } else {
      getReduceRateQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  const gerReduceRateStatus = async () => {
    setLoading(true);
    const res = await SettingsApi.getReduceRateStatus();
    if (res?.status === 200) {
      if (res?.data?.reduce_rate_status === false) {
        getReduceRateQuestions();
        setalreadySubmitted(false);
        setLoading(false);
      } else {
        // setresponseAnswer(res?.data);
        setalreadySubmitted(true);
        setLoading(false);
      }
    }
  };

  const getReduceRateQuestions = async () => {
    setLoading(true);
    let res;
    if (loginStatus) {
      res = await SettingsApi.getReduceRateQuestions();
    } else {
      res = await NetworkClient.getWithoutAuth("api/v3/reduce-rate-question", {
        platform_type: "ALL",
      });
    }
    if (res?.status === 200) {
      setreduceRateFormId(res?.data?.reduce_rate_form?.id);
      setquestions(res?.data?.reduce_rate_questions);
      googleTagFire("Reduce Rate", "Page View", res?.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else {
      setLoading(false);
    }
  };

  const submitData = async (values) => {
    if (
      form.getFieldsError().filter((item) => item?.errors?.length > 0).length >
      0
    ) {
      showMessege("error", "Please fill up the form");
      return;
    }

    let payload = {
      reduce_rate_form: reduceRateFormId,
      platform_type: "WEB",
    };

    let answers = [];

    for (const [key, value] of Object.entries(values)) {
      let finishLoop = false;
      let obj = {};
      for (var x of questions) {
        for (var b of x?.reduce_rate_questions) {
          if (b?.id === key) {
            obj = b;
            finishLoop = true;
            break;
          }
        }
        if (finishLoop) {
          break;
        }
      }

      if (obj?.question_type === "TEXT" || obj?.question_type === "NUMBER") {
        answers.push({
          reduce_rate_question: key,
          options: [],
          text: value,
          order: obj?.order,
        });
      } else {
        answers.push({
          reduce_rate_question: key,
          options: typeof value === "string" ? [value] : value,
          text: null,
          order: obj?.order,
        });
      }
    }

    for (var a of dateArray) {
      answers.push({
        reduce_rate_question: a?.id,
        options: [],
        text: a?.value,
      });
    }

    payload["answers"] = answers;
    googleTagFire("Submit", null, payload);

    let res;
    if (loginStatus) {
      res = await SettingsApi.submitReduceRateQuestions(payload);
    } else {
      res = await NetworkClient.postWithoutAuth(
        "api/v3/reduce-rate-question",
        payload
      );
    }

    if (res?.status === 201 || res?.status === 200) {
      showMessege("success", res?.data?.message ?? "Successfully submitted");
      form.resetFields();
      if (loginStatus) {
        gerReduceRateStatus();
      } else {
        getReduceRateQuestions();
      }

      setalreadySubmitted(true);

      setLoading(false);
    } else {
      showMessege("error", res?.data?.detail ?? "Submission failed");
      setLoading(false);
    }
  };

  const getCheckBoxGroupOptions = (options) => {
    let array = [];
    for (let op of options) {
      let obj = {
        label: op?.name,
        value: op?.name,
        style: { lineHeight: 2, fontSize: 17, fontFamily: "Archivo" },
      };
      array?.push(obj);
    }
    return array;
  };

  if (loading) {
    return <Loader />;
  } else {
    if (alreadySubmitted) {
      return (
        <div className={styles.submittedContainer}>
          <Row>
            <Col span={24}>
              <Space size={"large"} style={{ paddingTop: 15 }}>
                <p className={styles.headerTitle}>
                  Your Application Has Been Submitted
                </p>
              </Space>
              <p className={styles.description}>
                Thank you for completing the Reduced Rate Therapy program
                application. <br />
                We will reach out to your shortly with a decision!
              </p>
            </Col>
            <Col
              span={24}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                className={styles.returnHomeBtn}
                bodyStyle={{ paddingTop: 10, paddingBottom: 10 }}
                onClick={() => navigate("/")}
              >
                <Typography.Text className={styles.nextBtnText}>
                  {"Return Home"}
                </Typography.Text>
              </Card>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div className={styles.container}>
          <Row style={{ justifyContent: "center" }}>
            <Col lg={16} md={18} sm={20} xs={20}>
              <Form
                colon={false}
                name="basic"
                layout="vertical"
                form={form}
                initialValues={{}}
                onFinish={submitData}
                onFinishFailed={submitData}
                autoComplete="off"
              >
                {questions?.map((question, index) => (
                  <div key={index}>
                    {question?.is_header ? (
                      <div
                        style={{
                          paddingTop: 20,
                        }}
                      >
                        <Row>
                          <Col
                            span={3}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FontAwesomeIcon
                              icon={faChevronLeft}
                              size="lg"
                              onClick={() => navigate(-1)}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </Col>
                          <Col span={18}>
                            <p className={styles.headerTitle}>
                              {question?.name}
                            </p>
                          </Col>
                        </Row>

                        {question?.description && (
                          <p className={styles.headerDescription}>
                            {question?.description}
                          </p>
                        )}
                      </div>
                    ) : (
                      <>
                        {question?.name && (
                          <p className={styles.title}>{question?.name}</p>
                        )}
                        {question?.description && (
                          <p className={styles.description}>
                            {question?.description}
                          </p>
                        )}
                      </>
                    )}

                    {question?.reduce_rate_questions?.map((q, index) => (
                      <Fragment key={index}>
                        {q?.name && (
                          <p className={styles.label}>
                            {q?.name}
                            {q?.is_required && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </p>
                        )}

                        {q?.description && (
                          <p className={styles.descriptionMini}>
                            {q?.description}
                          </p>
                        )}

                        {q?.question_type === "TEXT" && (
                          <Form.Item
                            name={q?.id}
                            rules={[
                              {
                                required: q?.is_required,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <Input className={styles.inputStyle} />
                          </Form.Item>
                        )}

                        {q?.question_type === "DOB" && (
                          <DatePicker
                            format="MM/DD/YYYY"
                            className={styles.DatePicker}
                            placeholder="Select birthdate"
                            disabledDate={(date) => {
                              if (dayjs(date) < dayjs().add(-1, "day")) {
                                return false;
                              }
                              return true;
                            }}
                            onChange={(date) => {
                              setdateArray((d) => [
                                ...d,
                                {
                                  id: q?.id,
                                  value: dayjs(date).format("YYYY-MM-DD"),
                                },
                              ]);
                            }}
                          />
                        )}

                        {q?.question_type === "NUMBER" && (
                          <Form.Item
                            name={q?.id}
                            rules={[
                              {
                                required: q?.is_required,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <Input
                              inputMode="decimal"
                              className={styles.inputStyle}
                            />
                          </Form.Item>
                        )}

                        {q?.question_type === "SINGLE_SELECT" && (
                          <Form.Item
                            name={q?.id}
                            rules={[
                              {
                                required: q?.is_required,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <Radio.Group
                              buttonStyle="outline"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              options={getCheckBoxGroupOptions(q?.options)}
                            />
                          </Form.Item>
                        )}

                        {q?.question_type === "MULTI_SELECT" && (
                          <Form.Item
                            name={q?.id}
                            rules={[
                              {
                                required: q?.is_required,
                                message: "This field is required.",
                              },
                            ]}
                          >
                            <Checkbox.Group
                              options={getCheckBoxGroupOptions(q?.options)}
                            />
                          </Form.Item>
                        )}
                      </Fragment>
                    ))}
                  </div>
                ))}
              </Form>
              {questions?.length > 0 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="submit"
                    className={styles.saveBtn}
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Submit
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </div>
      );
    }
  }
}
