import React from "react";

const SliderFlag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M3.54601 14.0109L3.80399 13.9388C4.05212 13.8694 4.19706 13.6121 4.12772 13.3639L1.17828 2.80902C1.13666 2.66007 1.03758 2.53375 0.902816 2.45785C0.768057 2.38196 0.608668 2.36271 0.459714 2.40433L0.426398 2.41363C0.1162 2.5003 -0.0650082 2.82203 0.0216579 3.13223L2.97117 13.6871C3.04051 13.9353 3.29787 14.0802 3.54601 14.0109Z"
        fill="#1BCDB7"
      />
      <path
        d="M13.9321 6.63667C12.9967 6.17029 12.1314 5.57501 11.3615 4.86821C11.2968 4.80897 11.2715 4.71803 11.2963 4.63387C11.6778 3.19064 11.9611 1.72323 12.1443 0.24171C12.1733 -0.00471464 12.0404 -0.0771925 11.9003 0.0919225C9.15093 3.38 4.39397 -0.509644 1.5625 2.47886C1.59649 2.54251 1.62324 2.60977 1.64223 2.67938L3.55564 9.53096C6.39194 6.57869 11.132 10.4442 13.8765 7.16335C14.0166 6.99423 14.0432 6.68499 13.9321 6.63667Z"
        fill="#1BCDB7"
      />
    </svg>
  );
};

export default SliderFlag;
