import { put } from "redux-saga/effects";
import { BookingApi } from "../api";
import { constant } from "../constant";
// workers
function* getInstructorAvailablitySagaAction(param) {
  try {
    const res = yield BookingApi.getInstructorAvailabiltyData(
      param?.instructor_id,
      param?.studio_location,
      param?.appointment_type_id,
      param?.payload
    );
    if (res.status === 200) {
      const payload = {
        type: constant.GET_INSTRUCTOR_AVAILABLITY_SUCCESS,
        result: res.data,
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_INSTRUCTOR_AVAILABLITY_FAILED,
        errMsg: "Invalid instructor availablity data",
      };
      yield put(payload);
    }
  } catch (error) {
    if (error.response) {
      const payload = {
        type: constant.GET_INSTRUCTOR_AVAILABLITY_FAILED,
        errMsg: error.data,
      };
      yield put(payload);
    }
  }
}

export { getInstructorAvailablitySagaAction };
