export const CloseButton = ({ height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height ?? "50"}
      height={width ?? "50"}
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle cx="20" cy="20" r="20" fill="#CCD5E3" fillOpacity="0.3" />
      <path
        d="M20.5613 20.4642L25.8263 15.1992C25.9447 15.0611 26.0065 14.8833 25.9995 14.7016C25.9924 14.5198 25.9171 14.3474 25.7885 14.2188C25.6598 14.0901 25.4874 14.0148 25.3057 14.0078C25.1239 14.0007 24.9462 14.0626 24.808 14.1809L19.543 19.4459L14.278 14.1737C14.1398 14.0553 13.9621 13.9935 13.7804 14.0005C13.5986 14.0076 13.4262 14.0829 13.2975 14.2115C13.1689 14.3402 13.0936 14.5126 13.0865 14.6943C13.0795 14.8761 13.1414 15.0538 13.2597 15.192L18.5247 20.4642L13.2524 25.7292C13.1768 25.794 13.1154 25.8736 13.0721 25.9632C13.0287 26.0528 13.0044 26.1504 13.0005 26.2499C12.9967 26.3494 13.0135 26.4486 13.0498 26.5412C13.0861 26.6339 13.1412 26.7181 13.2115 26.7885C13.2819 26.8589 13.3661 26.9139 13.4588 26.9502C13.5514 26.9865 13.6506 27.0033 13.7501 26.9995C13.8496 26.9956 13.9472 26.9713 14.0368 26.9279C14.1264 26.8846 14.206 26.8232 14.2708 26.7476L19.543 21.4826L24.808 26.7476C24.9462 26.8659 25.1239 26.9277 25.3057 26.9207C25.4874 26.9137 25.6598 26.8383 25.7885 26.7097C25.9171 26.5811 25.9924 26.4086 25.9995 26.2269C26.0065 26.0451 25.9447 25.8674 25.8263 25.7292L20.5613 20.4642Z"
        fill="#25364E"
      />
    </svg>
  );
};
