import { put } from "redux-saga/effects";
import { MbcApi } from "../api";
import { constant } from "../constant";

// workers
function* getMbcDataSagaAction() {
  try {
    const res = yield MbcApi.getMbcAvailability();
    const response = yield MbcApi.getCheckInData();
    if (res?.status === 200 && response?.status === 200) {
      const payload = {
        type: constant.GET_MBC_DATA_SUCCESS,
        data: {
          mbcSubmitId: res?.data?.data?.id,
          title: res?.data?.title,
          des: res?.data?.description,
          sliderData: response?.data?.sliding_screens,
          questionData: response?.data?.questions,
        },
      };
      yield put(payload);
    } else {
      const payload = {
        type: constant.GET_MBC_DATA_FAILED,
        availabilityErr: res,
        checkInErr: response,
      };
      yield put(payload);
    }
  } catch (error) {
    if (error) {
      const payload = {
        type: constant.GET_MBC_DATA_FAILED,
        availabilityErr: error,
        checkInErr: error,
      };
      yield put(payload);
    }
  }
}

export { getMbcDataSagaAction };
