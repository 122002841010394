import { constant } from "../constant";

const initialState = {
  loading: true,
  result: {},
  errMsg: ""
};

const ConfirmBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.CONFIRM_BOOKING:
      return {
        ...state,
        loading: false,
        result: action.result,
        errMsg: "",
        // payload: action.payload,
      };
    case constant.CLEAN_CONFIRM_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default ConfirmBookingReducer;
