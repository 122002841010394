export const getMBCColorForValue = (value) => {
  switch (true) {
    case value === null || value === 6.5 || value === 6.6:
      return "transparent";
    case value <= 4:
      return "#52B534";
    case value > 4 && value <= 10:
      return "#99E582";
    case value > 10 && value <= 14:
      return "#F5D52E";
    case value > 14 && value <= 19:
      return "#FC9012";
    case value > 19 && value <= 25:
      return "#FC5D12";
    case value > 25:
      return "#E42E37";
    default:
      return "#E42E37"; // for values > 30 or anything that doesn't match
  }
};


export const getMBCColorName = (value) => {
  switch (true) {
    case value === null || value === 6.5 || value === 6.6:
      return "transparent";
    case value <= 4:
      return "Healthy";
    case value > 4 && value <= 10:
      return "Low & Healthy";
    case value > 10 && value <= 14:
      return "Mild";
    case value > 14 && value <= 19:
      return "Moderate";
    case value > 19 && value <= 25:
      return "Moderate to Severe";
    case value > 25:
      return "Severe";
    default:
      return "Severe"; // for values > 30 or anything that doesn't match
  }
};

