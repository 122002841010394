import { put } from "redux-saga/effects";
import { constant } from "../constant";
// workers

function* userLogOutAction() {
  localStorage.removeItem("persist:roots");
  localStorage.removeItem("roots");
  localStorage.removeItem("submission_id");
  localStorage.removeItem("instructor_id");
  localStorage.clear();
  const payload = {
    type: constant.LOGOUT_SUCCESS,
    loginStatus: false,
  };
  yield put(payload);
}

export { userLogOutAction };
