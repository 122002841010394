import React from "react";

const MbcAvatar = () => {
  return (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="love 1" clipPath="url(#clip0_19303_11436)">
        <g id="Group">
          <path
            id="Vector"
            d="M36.2039 21.2229L33.2403 15.9384C33.3114 7.46789 26.4244 0.526999 17.9544 0.531252C13.8818 0.531252 10.0541 2.10344 7.17744 4.95784C4.30043 7.81262 2.69907 11.6277 2.6684 15.7002C2.64863 18.3324 3.30944 20.933 4.57942 23.2226C5.64682 25.2043 7.86646 29.7845 5.14584 37.2119C5.01257 37.5562 5.05757 37.9441 5.26627 38.2487C5.47505 38.5532 5.82053 38.7352 6.18966 38.7352H20.8162C21.3963 38.7352 21.8805 38.2893 21.9313 37.7115C21.9337 37.6842 22.1677 35.202 23.5204 33.7179L26.8657 34.8854C28.4752 35.451 30.2297 35.1861 31.6117 34.1635C32.9535 33.1736 33.752 31.5892 33.7476 29.925L33.7372 25.945L34.9235 25.3584C35.6537 24.9972 36.2108 24.3438 36.4516 23.5656C36.6927 22.7873 36.6023 21.9334 36.2039 21.2229ZM34.3134 22.9035C34.2524 23.1009 34.1165 23.2602 33.9313 23.3518L32.1202 24.2473C31.7376 24.4365 31.4959 24.8268 31.497 25.2536L31.5092 29.9309C31.5117 30.8992 31.0648 31.7854 30.2829 32.3621C29.4994 32.9417 28.5089 33.0917 27.6031 32.7718L18.9136 29.788C18.3285 29.5899 17.6929 29.9037 17.4945 30.4891C17.2963 31.0746 17.6102 31.71 18.1956 31.9083L21.2855 32.9546C20.4553 34.1784 20.0508 35.5726 19.8603 36.4967H7.76221C9.95283 28.9196 7.65133 24.1933 6.54782 22.1561C5.45751 20.1985 4.89005 17.9719 4.90706 15.7172C4.93325 12.2394 6.29949 8.98269 8.75432 6.54689C11.2089 4.11116 14.4763 2.76977 17.9545 2.76977C25.1491 2.76984 31.0023 8.62304 31.0023 15.8176C30.9988 16.1236 30.9634 16.4887 31.1393 16.7683L34.2514 22.3177C34.3525 22.4981 34.3745 22.7061 34.3134 22.9035Z"
            fill="#883DBF"
          />
          <path
            id="Vector_2"
            d="M17.953 7.90594C16.1957 6.38741 13.5285 6.4618 11.8605 8.12987C11.0147 8.97565 10.5488 10.1003 10.5488 11.2965C10.5488 12.4927 11.0147 13.6174 11.8605 14.4631L17.1537 19.7721C17.3722 19.9906 17.6586 20.1 17.9451 20.1H17.961C18.2475 20.1 18.534 19.9906 18.7524 19.7721L24.0456 14.4631C24.8914 13.6173 25.3573 12.4927 25.3573 11.2965C25.3573 10.1003 24.8914 8.97558 24.0456 8.12987C22.3776 6.4621 19.7103 6.38748 17.953 7.90594ZM22.4627 12.8803L17.953 17.4058L13.4433 12.8803C13.0202 12.4572 12.7873 11.8948 12.7873 11.2965C12.7873 10.6982 13.0202 10.1357 13.4433 9.71265C14.3165 8.83948 15.7374 8.83933 16.6109 9.71265L17.1616 10.2633C17.5986 10.7004 18.3073 10.7004 18.7444 10.2633L19.2951 9.71265C20.1684 8.83933 21.5893 8.83948 22.4627 9.71265C22.8857 10.1357 23.1187 10.6982 23.1187 11.2965C23.1187 11.8948 22.8858 12.4572 22.4627 12.8803Z"
            fill="#883DBF"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_19303_11436">
          <rect
            width="38.204"
            height="38.204"
            fill="white"
            transform="translate(0.529297 0.53125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MbcAvatar;
