import { constant } from "../constant";

const initialState = {
  loading: true,
  loginStatus: false,
  jwt: "",
  refresh_token: "",
  errMsg: "",
  fCredentials: {},
  sider: false,
  userInfo: {},
  userProfileInfo: {},
  settingsRoute: 0,
  loginLoader: false,
  gotoReduceRate: false,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.LOGOUT: {
      return {
        ...state,
        loading: true,
      };
    }
    case constant.LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: true,
        loginStatus: false,
        jwt: "",
        refresh_token: "",
        errMsg: "",
        fCredentials: {},
      };
    }
    case constant.FIREBASE_CREDENTIALS: {
      return {
        ...state,
        fCredentials: action.payload,
      };
    }
    case constant.SIDER_CONDITION:
      return {
        ...state,
        sider: action.payload,
      };
    case constant.USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case constant.USER_PROFILE_INFO:
      return {
        ...state,
        userProfileInfo: action.payload,
      };
    case constant.SET_JWT:
      return {
        ...state,
        jwt: action.jwt,
        loginStatus: action.loginStatus,
      };
    case constant.SET_SETTINGS_ROUTE:
      return {
        ...state,
        settingsRoute: action.payload,
      };
    case constant.SET_LOGIN_LOADER:
      return {
        ...state,
        loginLoader: action.payload,
      };
    case constant.REDUCE_RATE_OPEN:
      return {
        ...state,
        gotoReduceRate: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
