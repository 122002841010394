import axios from "axios";
import { auth } from "../../../utills/firebase-config";
import { API_BASE_URL } from "../config/config";
import { AuthApi } from "../index";
// import { logOutAction } from "../../action/user";
// import { store } from "../../../store";

var moment = require("moment-timezone");

const setToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

const request = async (config) => {
  axios.defaults.baseURL = API_BASE_URL;
  axios.defaults.headers.common["PLATFORM"] = "WEB";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["LOCAL-TIMEZONE"] = moment.tz.guess();
  const callBack = axios(config);
  return callBack
    .then((response) => {
      console.log(
        "🚀 ~ file: AxiosConfig.js ~ line 18 ~ .then ~ response",
        response
      );
      return callBack;
    })
    .catch(async (error) => {
      console.log(
        "🚀 ~ file: AxiosConfig.js ~ line 21 ~ request ~ error",
        error.response
      );
      if (error.response == null) {
        return error;
      } else if (error.response.status === 401) {
        const result = await refreshToken();
        if (result !== null) {
          try {
            config.headers = {
              common: {
                "X-JWT-TOKEN": `Bearer ${result}`,
              },
            };
          } catch (error) {}

          return axios(config);
        } else {
          return axios(config);
        }
      } else if (error.response.status === 403) {
        return error;
      } else if (error.response.status === 400) {
        return error?.response;
      } else if (error.code === "ECONNABORTED") {
        return axios(config);
      } else {
        if (error?.response?.data?.message) {
        }
        return error;
      }
    });
};

const refreshToken = async () => {
  const fToken = await auth.currentUser.getIdToken();
  const fireToken = await auth.currentUser.getIdTokenResult(true);
  if (fToken || fireToken) {
    const name = localStorage.getItem("name");
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const res = await AuthApi.login({
      fire_token: fToken ?? fireToken?.token,
      preferred_name: name,
      first_name: firstName ?? null,
      last_name: lastName ?? null,
    });
    const data = res?.data ?? null;
    setToken(data?.jwt ?? null);
    localStorage.setItem("token", data?.jwt ?? null);
    return data?.jwt ?? null;
  } else {
    return null;
  }
};

export { request, setToken, refreshToken };
