import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { Progress } from "antd";
import { showMessege } from "../../components/CustomMessage";
import ConfirmDelete from "../../components/Modals/ConfirmDelete";
import { useNavigate } from "react-router-dom";
import NeedHelpStaticModal from "../../components/Modals/NeedHelpStaticModal";
import { useSelector } from "react-redux";
import { MbcApi } from "../../store/api";
import Loader from "../../components/Loader";

function RoundDiv({ color, children }) {
  const roundDivStyle = {
    width: "140px",
    height: "140px",
    backgroundColor: "#fff",
    border: `3px solid ${color}`,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 10,
    marginBottom: 10,
  };

  return <div style={roundDivStyle}>{children}</div>;
}

const MbcQuestionaire = () => {
  const navigate = useNavigate();
  const state = useSelector((state) => state.MbcReducer);
  const [data, setData] = useState(state?.questionData);
  const [loading, setloading] = useState(false);
  const [progress, setprogress] = useState(0);
  const [currentIndex, setcurrentIndex] = useState(0);
  const [showCloseModal, setshowCloseModal] = useState(false);
  const [needHelpModal, setNeedHelpModal] = useState(false);
  const [isCompleted, setisCompleted] = useState(false);
  const [score, setscore] = useState(0);

  useEffect(() => {
    const progressHandle = async () => {
      const filteredItems = data.filter((item) =>
        item.options.some((option) => option.selected === true)
      );
      if (filteredItems?.length > 0) {
        const res = (filteredItems.length * 100) / data.length;
        setprogress(res);
      }
    };
    data?.length > 0 && progressHandle();
  }, [data]);

  const handleAnswer = async (j, riskStatus) => {
    const cloneData = JSON.parse(JSON.stringify(data));
    const updatedArr = cloneData.map((item, mainIndex) => {
      if (mainIndex === currentIndex) {
        return {
          ...item,
          options: item.options.map((answer, childIndex) => {
            if (childIndex === j) {
              return { ...answer, selected: true };
            } else {
              return { ...answer, selected: null };
            }
          }),
        };
      }
      return item;
    });
    if (
      riskStatus &&
      updatedArr?.[currentIndex]?.options?.[j]?.name !== "Not at all"
    ) {
      setNeedHelpModal(true);
    }
    setData(updatedArr);
  };

  const backNextHandle = async (param) => {
    if (param === "back") {
      setcurrentIndex((e) => e - 1);
    } else {
      if (isCompleted) {
        navigate("/my-journey"); //finished
      } else if (data?.length === currentIndex + 1) {
        questionnaireSubmit();
      } else {
        const isAnswered =
          data?.[currentIndex]?.options?.length > 0
            ? data?.[currentIndex]?.options.filter((e) => e?.selected)
            : [];
        if (data?.length !== currentIndex + 1 && isAnswered.length > 0) {
          setcurrentIndex((e) => e + 1);
        } else {
          showMessege("error", "Please select the answer");
        }
      }
    }
  };

  const questionnaireSubmit = async () => {
    setloading(true);
    const updateArr = data
      .map((item) => {
        const selectedOption = item.options.find(
          (option) => option.selected === true
        );
        if (selectedOption) {
          return {
            title: item.title,
            option: selectedOption.name,
            value: selectedOption.value,
            risk_status: selectedOption.risk_status,
          };
        }
        return null;
      })
      .filter(Boolean);
    const submitRes = await MbcApi.putCheckInData(state?.mbcSubmitId, {
      result: updateArr,
    });
    if (submitRes?.status === 200 || submitRes?.status === 201) {
      setisCompleted(true);
      setscore(submitRes?.data?.score);
    } else {
      showMessege("error", submitRes?.data?.message);
    }
    setloading(false);
  };

  const closeHandle = async () => {
    setloading(true);
    await MbcApi.closeCheckInMbc(state?.mbcSubmitId);
    setloading(false);
    navigate("/");
  };

  const scoreColor = useMemo(() => {
    let res = {};
    if (score >= 0 && score <= 4) {
      res.color = "#52B534";
      res.text = "You scored in the healthy range";
    } else if (score >= 5 && score <= 10) {
      res.color = "#99E582";
      res.text = "You scored in the low distress and healthy range";
    } else if (score >= 11 && score <= 14) {
      res.color = "#F5D52E";
      res.text = "You scored in the mild distress range";
    } else if (score >= 15 && score <= 19) {
      res.color = "#FC9012";
      res.text = "You scored in the moderate distress range";
    } else if (score >= 20 && score <= 25) {
      res.color = "#FC5D12";
      res.text =
        "You seem to be having a rough time, with a score falling in the moderate to severe distress range";
    } else if (score > 25) {
      res.color = "#E42E37";
      res.text =
        "You seem to be having a rough time, with a score falling in the severe distress range";
    } else {
      res.color = "#D3D3D3";
      res.text = "";
    }
    return res;
  }, [score]);

  return (
    <div className={styles.mainContainer}>
      {loading && <Loader />}
      <p className={styles.header}>
        {isCompleted
          ? "Good job completing your check-in!"
          : "Over the last week"}
      </p>

      {isCompleted && (
        <p className={styles.des}>
          This questionnaire examines how you have been doing (aka your mental
          state). Higher scores mean that you are experiencing more distress.
          Although it can feel concerning to see a higher score, completing the
          assessment immediately before/during your session allows you and your
          therapist to gain insight into your distress levels and engage in
          treatment planning together.
        </p>
      )}

      <div className={styles.container}>
        {!isCompleted && (
          <Progress
            percent={progress}
            showInfo={false}
            size={[, 2]}
            strokeColor={"#1BCDB7"}
            className={styles.progressBar}
          />
        )}

        {/* question & answers */}
        {!isCompleted && (
          <div className={styles.flexColumn}>
            <p className={styles.question}>“{data?.[currentIndex]?.title}”</p>
            {data?.[currentIndex]?.options?.length > 0 &&
              data?.[currentIndex]?.options.map((a, j) => (
                <div
                  className={
                    a?.selected ? styles.answerBoxSelected : styles.answerBox
                  }
                  key={j}
                  onClick={() => handleAnswer(j, a?.risk_status ?? false)}
                >
                  {a?.name}
                </div>
              ))}
          </div>
        )}

        {isCompleted && (
          <div className={styles.flexColumn}>
            <p className={styles.question}>Here is your score</p>
            <RoundDiv color={scoreColor.color}>
              <p
                className={styles.totalScore}
                style={{ color: scoreColor.color }}
              >
                {score}
              </p>
              <p className={styles.outOfScore}>Out of 40</p>
            </RoundDiv>
            <p className={styles.scoreDes}>{scoreColor.text}</p>
            <p className={styles.bottomText1}>
              You can view your progress in your “Insights” page and discuss
              results with your therapist in your upcoming session.
            </p>
          </div>
        )}

        <div className={styles.sliderBtnContainer}>
          {currentIndex !== 0 && !isCompleted && (
            <div
              className={styles.sliderBack}
              onClick={() => backNextHandle("back")}
            >
              Back
            </div>
          )}

          <div
            className={styles.sliderNext}
            onClick={() => backNextHandle("next")}
            style={{ borderRadius: isCompleted ? 21 : 8 }}
          >
            {isCompleted
              ? "See Insights"
              : currentIndex + 1 === data.length
              ? "Finish"
              : "Next"}
          </div>
        </div>

        {isCompleted ? (
          <p className={styles.help} onClick={() => setNeedHelpModal(true)}>
            Need Help?
          </p>
        ) : (
          <p className={styles.bottomText}>
            If you do not want to continue to the measurement,{" "}
            <span
              className={styles.bottomTextRed}
              onClick={() => setshowCloseModal(true)}
            >
              Close Check-In
            </span>
          </p>
        )}
      </div>

      <ConfirmDelete
        showModal={showCloseModal}
        setShowModal={setshowCloseModal}
        submitClick={() => closeHandle()}
        title="Close this Check-in?"
        des="Your responses will not be submitted if you exit."
        noBtnText="No"
        closeBtnText="Yes, Close It"
      />
      <NeedHelpStaticModal
        showModal={needHelpModal}
        setShowModal={setNeedHelpModal}
      />
    </div>
  );
};

export default MbcQuestionaire;
