import http from "./config/NetworkClient";

export default class SettingsApi {
  static async getCardList(payload) {
    const response = await http.get("/api/v3/user-cards");
    return response;
  }
  static async addNewCard(payload) {
    const response = await http.post("/api/v3/user-card", payload);
    return response;
  }
  static async deleteCard(id) {
    const response = await http.put(`/api/v3/user-card/${id}`);
    return response;
  }

  static async changeDefaultCard(param) {
    const response = await http.put(`/api/v3/user-card/${param?.id}/default`, {
      default: param?.default,
    });
    return response;
  }

  static async getBillingsList() {
    const response = await http.get("/api/v3/user-benefit-providers");
    return response;
  }
  static async addDirectBilling(payload) {
    const response = await http.post("/api/v3/user-benefit-providers", payload);
    return response;
  }
  static async updateDirectBilling(id, payload) {
    const response = await http.put(
      `/api/v3/user-benefit-providers/${id}`,
      payload
    );
    return response;
  }
  static async deleteDirectBilling(id) {
    const response = await http.post(
      `/api/v3/user-benefit-providers/${id}/archive`
    );
    return response;
  }
  static async detailsDirectBilling(id) {
    const response = await http.get(`/api/v3/user-benefit-providers/${id}`);
    return response;
  }
  static async directBillingProviders() {
    const response = await http.get(
      `/api/v3/benefit-providers?page=1&limit=100&is_published=true`
    );
    return response;
  }

  static async makeEmailVerificationRequest() {
    const response = await http.post("/api/v3/email-verification");
    return response;
  }

  static async verifyEmail(payload) {
    const response = await http.post("/api/v3/verify-email", payload);
    return response;
  }
  static async makeChangeEmailRequest(payload) {
    const response = await http.post("/api/v3/change-email", payload);
    return response;
  }
  static async verifyChangeEmail(payload) {
    const response = await http.post("/api/v3/verify-change-email", payload);
    return response;
  }
  static async getReduceRateQuestions() {
    const response = await http.get(
      "/api/v3/reduce-rate-question?platform_type=ALL"
    );
    return response;
  }
  static async getReduceRateStatus() {
    const response = await http.get("/api/v3/user-reduce-rate");
    return response;
  }
  static async submitReduceRateQuestions(payload) {
    const response = await http.post("/api/v3/reduce-rate-question", payload);
    return response;
  }

  //waitlist
  static async getUserWaitlistInfo() {
    const response = await http.get(`/api/v3/pre_waitlist_info`);
    return response;
  }
  static async getWaitlist() {
    const response = await http.get(`/api/v3/waitlist`);
    return response;
  }
  static async archiveWaitlist(id) {
    const response = await http.post(`/api/v3/waitlist/${id}/archive`);
    return response;
  }
  static async addWaitlist(payload) {
    const response = await http.post(`/api/v3/waitlist`, payload);
    return response;
  }
  static async updateWaitlist(id, payload) {
    const response = await http.put(`/api/v3/waitlist/${id}`, payload);
    return response;
  }
  static async getUserInstructors() {
    const response = await http.get(`/api/v3/user_instructors`);
    return response;
  }
  static async getAppointmentTypes(id) {
    const response = await http.get(
      `/api/v3/user_instructors/${id}/appointment_types`
    );
    return response;
  }
  static async getInvoices(page, limit) {
    const response = await http.get(
      `/api/v3/invoices?page=${page}&limit=${limit}`
    );
    return response;
  }
}
