import http from "./config/NetworkClient";

export default class QuestionnaireApi {
  static async getQuestionnaire() {
    const response = await http.get("/api/v3/questionnaire-form?platform_type=WEB");
    return response;
  }



}
